import { createTheme } from '@material-ui/core';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#1c84c6'
    },
    secondary: {
      main: '#ed5565'
    }
  },
});

export default Theme;