// React
import React, { Component, Fragment } from 'react';

// Socket io
import io from 'socket.io-client';

// Material ui
import {
  Container,
  Box,
  Grid,
  Chip,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Fab,
  Icon,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

// Helper
import { get, post } from '../../helpers/ApiHelper';
import { getDate, now, yesterday, tomorrow } from '../../helpers/TimeHelper';

// Lodash
import { uniqBy, map } from 'lodash';

// Config
import Config from '../../const/Config';

// Component
import Nav from '../../components/Nav';
import Loader from '../../components/Loader';
import ToolbarBuffer from '../../components/ToolbarBuffer';
import ErrorBox from '../../components/ErrorBox';

class ClassHomework extends Component {
  state = {
    isLoading: false,

    class_id: this.props.match.params.class_id,

    class_info: {},
    students: [],
    subjects: [],
    homework_types: [],
    homeworks: [],
    homework_dates: [],
    homework_outstandings: [],

    selectedDate: now(),

    tabIndex: 'create',

    open_edit_dialog: false,
    edit_homework: {
      id: null,
      create_date: now(),
      class_id: this.props.match.params.class_id,
      subject_id: '',
      homework_type_id: '',
      description: '',
      submit_date: now().add(1, 'days'),
    },
    edit_homework_errors: [],

    open_delete_dialog: false,
    delete_id: null,

    selectedHomework: null,
    open_collect_dialog: false,
    outstanding: [],
  };

  fetch = (callback) => {
    const { class_id } = this.state;

    let getClass = () =>
      get('getOneClass/' + class_id).then(({ status, result }) => {
        if (status !== false && result !== undefined) {
          this.setState({ class_info: result[0] });
        }
      });

    let getStudent = () =>
      get('getStudentByClass/' + class_id).then(({ status, result }) => {
        if (status !== false && result !== undefined) {
          this.setState({
            students: result,
          });
        }
      });

    let getSubject = () =>
      get('getSubject').then(({ status, result }) => {
        if (status !== false && result !== undefined) {
          this.setState({ subjects: result });
        }
      });

    let getHomeworkType = () =>
      get('getHomeworkType').then(({ status, result }) => {
        if (status !== false && result !== undefined) {
          this.setState({
            homework_types: result,
          });
        }
      });

    Promise.all([getClass(), getStudent(), getSubject(), getHomeworkType()]).then(() => {
      if (callback) {
        callback();
      }
    });
  };

  fetchHomework = (callback) => {
    const { class_id, tabIndex, selectedDate } = this.state;

    let getHomework = () =>
      get('getClassHomework/' + class_id + '/' + tabIndex + '/' + getDate(selectedDate)).then(
        ({ status, result }) => {
          if (status !== false && result !== undefined) {
            this.setState({
              homeworks: result,
              homework_dates: uniqBy(
                map(result, (homework) => {
                  if (tabIndex === 'create') {
                    return homework.create_date;
                  } else {
                    return homework.submit_date;
                  }
                })
              ),
            });
          }
        }
      );

    let getOutstanding = () =>
      get('getClassHomeworkOutstanding/' + class_id + '/' + tabIndex + '/' + getDate(selectedDate)).then(
        ({ status, result }) => {
          if (status !== false && result !== undefined) {
            this.setState({
              homework_outstandings: result,
            });
          }
        }
      );

    Promise.all([getHomework(), getOutstanding()]).then(() => {
      if (callback) {
        callback();
      }
    });
  };

  componentDidMount = () => {
    this.fetch(() => {
      this.fetchHomework(() => {
        this.setState({ isLoading: false });
      });
    });

    this.socket = io(Config.host);
  };

  newEditHomework = () => {
    const { class_id } = this.state;
    return {
      id: null,
      create_date: now(),
      class_id,
      subject_id: '',
      homework_type_id: '',
      description: '',
      submit_date: now().add(1, 'days'),
    };
  };

  onChangeCriteria = (stateName, value) => {
    this.setState(
      {
        isLoading: true,
        [stateName]: value,
      },
      () => {
        this.fetchHomework(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleEditDialog = (status, edit_homework = null) => {
    const { newEditHomework } = this;

    if (!edit_homework) {
      edit_homework = newEditHomework();
    }

    this.setState({
      open_edit_dialog: status,
      edit_homework,
      edit_homework_errors: [],
    });
  };

  changeEditHomework = (stateName, value) => {
    const { edit_homework } = this.state;
    this.setState({ edit_homework: { ...edit_homework, [stateName]: value } });
  };

  changeSubject = (stateName, value) => {
    const { edit_homework } = this.state;
    this.setState({ edit_homework: { ...edit_homework, [stateName]: value, homework_type_id: '' } });
  };

  save = () => {
    const { fetchHomework } = this;
    let { class_info, edit_homework } = this.state;
    let edit_homework_errors = [];

    if (!edit_homework.class_id) {
      edit_homework_errors.push('必需輸入班別');
    }

    if (!edit_homework.create_date) {
      edit_homework_errors.push('必需派發日期');
    }

    if (!edit_homework.subject_id) {
      edit_homework_errors.push('必需輸入科目');
    }

    if (!edit_homework.description) {
      edit_homework_errors.push('必需輸入描述');
    }

    if (!edit_homework.submit_date) {
      edit_homework_errors.push('必需輸入遞交日期');
    }

    if (edit_homework_errors.length > 0) {
      this.setState({ edit_homework_errors });
    } else {
      this.setState({ isLoading: true });
      edit_homework = {
        id: edit_homework.id,
        create_date: getDate(edit_homework.create_date),
        class_id: edit_homework.class_id,
        subject_id: edit_homework.subject_id,
        homework_type_id: edit_homework.homework_type_id,
        description: edit_homework.description,
        submit_date: getDate(edit_homework.submit_date),
      };

      post('editHomework', { year_id: class_info.year_id, edit_homework }).then(({ status }) => {
        if (status) {
          this.socket.emit('edit');
          fetchHomework(() => {
            this.setState({
              open_edit_dialog: false,
              edit_homework: this.newEditHomework(),
              edit_homework_errors: [],
              isLoading: false,
            });
          });
        }
      });
    }
  };

  handleRemoveDialog = (status, id = null) => {
    this.setState({
      open_delete_dialog: status,
      delete_id: id,
    });
  };

  remove = () => {
    const { fetchHomework } = this;
    const { delete_id } = this.state;

    this.setState({ isLoading: true });

    post('deleteHomework', { homework_id: delete_id }).then(({ status }) => {
      if (status) {
        this.socket.emit('edit');
        fetchHomework(() => {
          this.setState({
            isLoading: false,
            open_delete_dialog: false,
            delete_id: null,
          });
        });
      }
    });
  };

  handleCollectDialog = (status, selectedHomework = null) => {
    const { homework_outstandings } = this.state;
    let outstanding = [];
    if (selectedHomework) {
      homework_outstandings.forEach(({ homework_id, student_class_id }, index) => {
        if (homework_id === selectedHomework) {
          outstanding.push(student_class_id);
        }
      });
    }

    this.setState({
      selectedHomework,
      open_collect_dialog: status,
      outstanding,
    });
  };

  selectStudent = (id) => {
    const { outstanding } = this.state;
    const index = outstanding.indexOf(id);
    if (index !== -1) {
      outstanding.splice(index, 1);
    } else {
      outstanding.push(id);
    }
    this.setState({ outstanding });
  };

  isSelected = (id) => {
    const { outstanding } = this.state;
    const index = outstanding.indexOf(id);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  };

  collect = () => {
    const { fetchHomework } = this;
    const { selectedHomework, outstanding } = this.state;

    const data = {
      homework_id: selectedHomework,
      outstanding,
    };

    this.setState({ isLoading: true });
    post('collectHomework', data).then(({ status }) => {
      if (status) {
        this.socket.emit('edit');
        fetchHomework(() => {
          this.setState({
            isLoading: false,
            selectedHomework: null,
            open_collect_dialog: false,
            outstanding: [],
          });
        });
      }
    });
  };

  render() {
    const { history } = this.props;
    const {
      isLoading,
      class_info,
      students,
      subjects,
      homework_types,
      homeworks,
      homework_dates,
      homework_outstandings,
      selectedDate,
      tabIndex,
      open_edit_dialog,
      edit_homework,
      edit_homework_errors,
      open_delete_dialog,
      open_collect_dialog,
      outstanding,
    } = this.state;
    const {
      onChangeCriteria,
      handleEditDialog,
      changeEditHomework,
      changeSubject,
      save,
      handleRemoveDialog,
      remove,
      handleCollectDialog,
      selectStudent,
      isSelected,
      collect,
    } = this;

    return (
      <Fragment>
        <Loader isLoading={isLoading} />

        <div className="root">
          <Nav history={history} title={class_info.year_name + ' ' + class_info.class_name + '班 '} />

          <Container className="root-content">
            <ToolbarBuffer />

            <Box mb={3} display="flex" flexWrap="wrap" alignItems="center">
              <Box>
                <Fab
                  variant="extended"
                  color="primary"
                  size="medium"
                  onClick={() => handleEditDialog(true)}
                >
                  <Icon>add</Icon>新增家課
                </Fab>
              </Box>
            </Box>

            {/* homework list */}
            <Box mb={4}>
              <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, value) => onChangeCriteria('tabIndex', value)}
              >
                <Tab value="create" label="今日派發" />
                <Tab value="submit" label="今日遞交" />
              </Tabs>
            </Box>

            {/* date picker */}
            <Box mb={4} display="flex" alignItems="center">
              <Box>
                <Icon onClick={() => onChangeCriteria('selectedDate', yesterday(selectedDate))}>
                  arrow_back_ios
                </Icon>
              </Box>
              <Box ml={1} mr={2}>
                <KeyboardDatePicker
                  autoOk
                  inputVariant="outlined"
                  label="日期"
                  format="YYYY-MM-DD"
                  value={selectedDate}
                  onChange={(selectedDate) => onChangeCriteria('selectedDate', selectedDate.format())}
                />
              </Box>
              <Box>
                <Icon onClick={() => onChangeCriteria('selectedDate', tomorrow(selectedDate))}>
                  arrow_forward_ios
                </Icon>
              </Box>
            </Box>

            {/* homework list */}
            <Box>
              {homework_dates.length > 0 ? (
                homework_dates.map((date, index) => (
                  <Box key={index} mb={3}>
                    <Box>
                      {homeworks
                        .filter((homework) => {
                          if (tabIndex === 'create') return homework.create_date === date;
                          else return homework.submit_date === date;
                        })
                        .map((homework, index) => (
                          <ExpansionPanel key={index}>
                            <ExpansionPanelSummary className="expand-header expand-padding">
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Box display="flex" alignItems="center">
                                    <Icon className="margin-right-10 green-text">
                                      description
                                    </Icon>
                                    <b>
                                      {homework.subject_name}:{' '}
                                      {homework.homework_type_name
                                        ? '[' +
                                        homework.homework_type_name +
                                        ']'
                                        : ''}
                                      {homework.description}
                                    </b>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box display="flex" alignItems="center">
                                    <Icon className="margin-right-10">alarm</Icon>
                                    <b>{getDate(homework.submit_date)}</b>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box display="flex" alignItems="center">
                                    <Icon className="margin-right-10">
                                      assignment_ind
                                    </Icon>
                                    <b>
                                      {homework.collected ? (
                                        homework_outstandings.filter(
                                          (outstnding) =>
                                            outstnding.homework_id ===
                                            homework.id
                                        ).length > 0 ? (
                                          <span className="red-text">
                                            欠{' '}
                                            {
                                              homework_outstandings.filter(
                                                (outstnding) =>
                                                  outstnding.homework_id ===
                                                  homework.id
                                              ).length
                                            }{' '}
                                            人
                                          </span>
                                        ) : (
                                          <span className="green-text">
                                            已全部遞交
                                          </span>
                                        )
                                      ) : (
                                        '未進行點收'
                                      )}
                                    </b>
                                  </Box>
                                </Grid>
                              </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="expand-padding">
                              <Box px={1} py={1} flex={1}>
                                <Box mb={2}>
                                  <b className="margin-right-5">科目:</b>
                                  {homework.subject_name}
                                </Box>
                                <Box mb={2}>
                                  <b className="margin-right-5">種類:</b>
                                  {homework.homework_type_name
                                    ? homework.homework_type_name
                                    : '-'}
                                </Box>
                                <Box mb={2}>
                                  <b className="margin-right-5">描述:</b>
                                  {homework.description}
                                </Box>
                                <Box mb={2}>
                                  <b className="margin-right-5">派發日期:</b>
                                  {getDate(homework.create_date)}
                                </Box>
                                <Box mb={2}>
                                  <b className="margin-right-5">遞交日期:</b>
                                  {getDate(homework.submit_date)}
                                </Box>
                                <Box mb={2}>
                                  {homework.collected ? (
                                    homework_outstandings.filter(
                                      (outstnding) =>
                                        outstnding.homework_id === homework.id
                                    ).length > 0 ? (
                                      <Box>
                                        <b className="margin-right-5">欠交:</b>
                                        <Box
                                          display="flex"
                                          flexWrap="wrap"
                                          alignContent="flex-start"
                                          mt={2}
                                        >
                                          {homework_outstandings
                                            .filter(
                                              (outstanding) =>
                                                outstanding.homework_id ===
                                                homework.id
                                            )
                                            .map((outstanding, index) => (
                                              <Box
                                                key={index}
                                                mr={1}
                                                mb={1}
                                              >
                                                <Chip
                                                  color="secondary"
                                                  avatar={
                                                    <Avatar>
                                                      {
                                                        outstanding.class_number
                                                      }
                                                    </Avatar>
                                                  }
                                                  label={
                                                    outstanding.student_chi_name
                                                  }
                                                />
                                              </Box>
                                            ))}
                                        </Box>
                                      </Box>
                                    ) : (
                                      <span className="green-text">
                                        已全部遞交
                                      </span>
                                    )
                                  ) : (
                                    '未進行點收'
                                  )}
                                </Box>
                              </Box>
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions className="justify-content-center">
                              <Button
                                color="primary"
                                className="blue-text"
                                onClick={() => handleCollectDialog(true, homework.id)}
                              >
                                <Icon className="expand-button-icon">
                                  playlist_add_check
                                </Icon>
                                點收
                              </Button>
                              <Button
                                color="primary"
                                className="green-text"
                                onClick={() => handleEditDialog(true, homework)}
                              >
                                <Icon className="expand-button-icon">edit</Icon>編輯
                              </Button>
                              <Button
                                color="primary"
                                className="red-text"
                                onClick={() => handleRemoveDialog(true, homework.id)}
                              >
                                <Icon className="expand-button-icon">delete</Icon>刪除
                              </Button>
                            </ExpansionPanelActions>
                          </ExpansionPanel>
                        ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box>
                  <Alert severity="info">沒有功課記錄</Alert>
                </Box>
              )}
            </Box>

            {/* edit homework dialog */}
            <Dialog fullScreen open={open_edit_dialog} onClose={() => handleEditDialog(false)}>
              <AppBar className="dialog-app-bar">
                <Toolbar>
                  <IconButton color="inherit" onClick={() => handleEditDialog(false)}>
                    <Icon>close</Icon>
                  </IconButton>
                  <Typography variant="h6" className="dialog-title">
                    編輯家課
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box mt={3} mx={3}>
                <Container>
                  <Box display="flex" flexWrap="wrap">
                    <Box mb={3} mr={3} display="flex" alignItems="center">
                      <Icon className="margin-right-10">group</Icon>
                      <Box mr={1}>
                        <b>班別:</b>
                      </Box>
                      {class_info.class_name}
                    </Box>
                  </Box>

                  <Box mb={3}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      inputVariant="outlined"
                      label="派發日期*"
                      format="YYYY-MM-DD"
                      fullWidth
                      value={edit_homework.create_date}
                      onChange={(date) => changeEditHomework('create_date', date)}
                    />
                  </Box>

                  <Box mb={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="subject">科目*</InputLabel>
                      <Select
                        labelId="subject"
                        value={edit_homework.subject_id}
                        labelWidth={35}
                        onChange={(event) => changeSubject('subject_id', event.target.value)}
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem key={index} value={subject.id}>
                            {subject.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box
                    mb={3}
                    hidden={
                      homework_types.filter(
                        (homework_type) => homework_type.subject_id === edit_homework.subject_id
                      ).length === 0
                    }
                  >
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="class">家課種類</InputLabel>
                      <Select
                        labelId="class"
                        value={edit_homework.homework_type_id}
                        labelWidth={65}
                        onChange={(event) =>
                          changeEditHomework('homework_type_id', event.target.value)
                        }
                      >
                        {homework_types
                          .filter(
                            (homework_type) =>
                              homework_type.subject_id === edit_homework.subject_id
                          )
                          .map((homework_type, index) => (
                            <MenuItem key={index} value={homework_type.id}>
                              {homework_type.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box mb={3}>
                    <TextField
                      label="描述*"
                      variant="outlined"
                      fullWidth
                      value={edit_homework.description}
                      onChange={(event) => changeEditHomework('description', event.target.value)}
                    />
                  </Box>

                  <Box mb={3}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      inputVariant="outlined"
                      label="遞交日期*"
                      format="YYYY-MM-DD"
                      fullWidth
                      value={edit_homework.submit_date}
                      onChange={(date) => changeEditHomework('submit_date', date)}
                    />
                  </Box>

                  <Box mb={3} display={edit_homework_errors.length > 0 ? 'block' : 'none'}>
                    <ErrorBox errors={edit_homework_errors} />
                  </Box>

                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Box mr={3}>
                      <Fab
                        variant="extended"
                        size="medium"
                        onClick={() => handleEditDialog(false)}
                      >
                        <Icon>close</Icon>取消
                      </Fab>
                    </Box>
                    <Box>
                      <Fab
                        variant="extended"
                        color="primary"
                        size="medium"
                        onClick={() => save()}
                      >
                        <Icon>save</Icon>儲存
                      </Fab>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Dialog>
          </Container>

          {/* remove homework dialog */}
          <Dialog open={open_delete_dialog} onClose={() => handleRemoveDialog(false)}>
            <DialogTitle>確認刪除家課？</DialogTitle>
            <DialogContent>
              <DialogContentText>一旦刪除，此家課的相關資料將會一併刪除。</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleRemoveDialog(false)}>取消</Button>
              <Button onClick={() => remove()} color="secondary">
                刪除
              </Button>
            </DialogActions>
          </Dialog>

          {/* collect homework dialog */}
          <Dialog fullScreen open={open_collect_dialog} onClose={() => handleCollectDialog(false)}>
            <AppBar className="dialog-app-bar">
              <Toolbar>
                <IconButton color="inherit" onClick={() => handleCollectDialog(false)}>
                  <Icon>close</Icon>
                </IconButton>
                <Typography variant="h6" className="dialog-title">
                  點收家課
                </Typography>
              </Toolbar>
            </AppBar>
            <Box mt={3} mx={3}>
              <Container>
                <Box mb={4}>
                  <Typography variant="h6">請選擇欠交同學：</Typography>
                </Box>
                {students.length > 0 ? (
                  <Box>
                    <Box mb={5} display="flex" justifyContent="center" alignItems="center">
                      <Grid container spacing={3}>
                        {students.map((student, index) => (
                          <Grid key={index} item xs={6} sm={3}>
                            <Box
                              p={2}
                              textAlign="center"
                              borderRadius={10}
                              className={
                                isSelected(student.id) ? 'check-box' : 'uncheck-box'
                              }
                              onClick={() => selectStudent(student.id)}
                            >
                              {student.class_number} - {student.chi_name || student.eng_name}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box mb={1} textAlign="center">
                      已選擇 {outstanding.length} 人
                    </Box>
                    <Box mb={3} textAlign="center">
                      (如沒有欠交同學，請直接按儲存)
                    </Box>
                  </Box>
                ) : (
                  <Box mb={5}>沒有學生資料</Box>
                )}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box mr={3}>
                    <Fab
                      variant="extended"
                      size="medium"
                      onClick={() => handleCollectDialog(false)}
                    >
                      <Icon>close</Icon>取消
                    </Fab>
                  </Box>
                  <Box>
                    <Fab variant="extended" color="primary" size="medium" onClick={() => collect()}>
                      <Icon>save</Icon>儲存
                    </Fab>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

export default ClassHomework;
