
import React, { Fragment, useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Fab, Icon } from '@material-ui/core';
import { get } from '../../helpers/ApiHelper';
import { getCurrentYearId } from '../../helpers/TimeHelper';
import { uniq, map, find } from 'lodash';
import Loader from '../../components/Loader';
import Config from '../../const/Config';

const ClassLogin = () => {
  const context = useContext(AuthContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [gradeNames, setGradeNames] = useState([]);
  const [classNames, setClassNames] = useState([]);
  const [currentYearId, setCurrentYearId] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchYear = async () => {
      const { status, result } = await get('getYear');
      if (status !== false && result !== undefined) {
        const currentYearId = getCurrentYearId(result);
        setCurrentYearId(currentYearId);
      }
    }
    fetchYear();
  }, [])

  useEffect(() => {
    const fetchClass = async () => {
      const { status, result } = await get('getClass/' + currentYearId);
      if (status !== false && result !== undefined) {
        let gradeNames = [];
        let classNamesArray = [];

        map(result, ({ class_name }, index) => {
          gradeNames.push(class_name.substring(0, 1));
          classNamesArray.push(class_name.substring(1));
        })

        gradeNames = uniq(gradeNames);
        classNamesArray = uniq(classNamesArray);

        let classNames = [];
        while (classNamesArray.length > 0) {
          classNames.push(classNamesArray.splice(0, 5));
        }

        setGradeNames(gradeNames);
        setClassNames(classNames);
        setSchoolClasses(result);
      }
      setIsLoading(false);
    }
    if (currentYearId) fetchClass();
  }, [currentYearId])

  const login = () => {
    const schoolClass = find(schoolClasses, { class_name: selectedGrade + selectedClass });
    setIsLoading(true);
    if (schoolClass) {
      const classId = schoolClass.class_id
      context.toggleAuth({
        isAuth: true,
        role: 'class',
        isAdmin: false,
        chi_name: null,
        eng_name: null,
        classId
      });
      history.push('/blackboard');
    } else {
      setError('沒有此班別');
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />

      <Box className='blackboard-background white-text view-height-100' display='flex' alignItems='center' justifyContent='center'>
        <Box textAlign='center'>
          <Box mb={1}>
            <Typography variant="h5">
              {Config.schoolName}學校家課板
            </Typography>

          </Box>
          <Box mb={1}>
            <Typography variant="h6">
              選擇班別:
            </Typography>
          </Box>
          <Box mx={3} mb={1}>
            <Box display='flex' justifyContent='center'>
              {gradeNames.map((grade_name, index) => (
                <Box key={index} className={selectedGrade === grade_name ? 'class-box pointer check-box' : 'class-box pointer uncheck-box'} onClick={() => setSelectedGrade(grade_name)} p={2} m={1}>{grade_name}</Box>
              ))}
            </Box>
            <hr />
            {classNames.map((row, index) => (
              <Box key={index} display='flex' justifyContent='center'>
                {row.map((class_name, index) => (
                  <Box key={index} className={selectedClass === class_name ? 'class-box pointer check-box' : 'class-box pointer uncheck-box'} onClick={() => setSelectedClass(class_name)} p={2} m={1}>{class_name}</Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box mb={3} mx={5}>
            <Fab variant='extended' size='large' className='full-width' onClick={() => login()}>
              <Icon className='margin-right-10'>exit_to_app</Icon>進入
            </Fab>
          </Box>
          <Box>
            <Typography className='red-text' variant="h6">
              {error}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default ClassLogin;