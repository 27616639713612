// React
import React, { Component, Fragment } from "react";

// Socket io
import io from 'socket.io-client';

// Material ui
import { IconButton, Box, Icon, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";

// Helper
import { get } from '../../helpers/ApiHelper';
import { getDate, now, time } from '../../helpers/TimeHelper';

// Config
import Config from '../../const/Config';

// Component
import Loader from '../../components/Loader';

class ParentBlackboard extends Component {
    state = {
        isLoading: true, 

        school_class: {},
        subjects: [],
        homeworks: [],

        defaultSubjects: [
            {id: 1, name: '中文'},
            {id: 2, name: '英文'},
            {id: 3, name: '數學'},
            {id: 4, name: '常識'}
        ],

        selectedDate: now(),
        openCalendar: false,

        tabIndex: 'create'
    }

    fetch = (callback) => {
        const selectedClass = localStorage.getItem('classId');
      
        let getSubject = () => get('getSubject').then(({status, result}) => {
            if(status !== false && result !== undefined) {
                this.setState({subjects: result})
            }
        }); 

        let getClass = () => get('getOneClass/' + selectedClass).then(({status, result}) => {
            if(status !== false && result !== undefined) {
                if(result.length === 0) {
                    this.parentLogout()
                }

                this.setState({school_class: result.length > 0? result[0] : {}})
            }else{
            }
        }); 

        let getStudent = () => get('getStudentByClass/' + selectedClass).then(({status, result}) => {
            if(status !== false && result !== undefined) {
                this.setState({
                    students: result,
                })
            }
        });

        Promise.all([getSubject(), getClass(), getStudent()]).then(() => {
            if(callback) {
                callback();
            }
        });
    }

    fetchHomework = (callback) => {
        const { selectedDate, tabIndex } = this.state;
        const selectedClass = localStorage.getItem('classId');        

        let getClassHomework = () => get('getClassHomework/' + selectedClass + '/' + tabIndex + '/' + getDate(selectedDate)).then(({status, result}) => {
            if(status !== false && result !== undefined) {
                this.setState({
                    homeworks: result,
                })
            }
        });

        let getOutstanding = () => get('getClassHomeworkOutstanding/' + selectedClass + '/' + tabIndex + '/' + getDate(selectedDate)).then(({status, result}) => {
            if(status !== false && result !== undefined) {
                this.setState({
                    homework_outstandings: result,
                })
            }
        });

        Promise.all([getClassHomework(), getOutstanding()]).then(() => {
            if(callback) {
                callback();
            }
        });
    }

    componentDidMount() {
        // if(this.props.match.params.class_id) {
        //     localStorage.setItem('isAuth', true);
        //     localStorage.setItem('role', 'parent');
        //     localStorage.setItem('class', this.props.match.params.class_id);
        // }

        this.fetch(() => {
            this.fetchHomework(() => {
                this.setState({isLoading: false})
            });
        });

        setInterval(() => {
            if (time() === '0:00:00') {
                this.setState({selectedDate: now()})
            }
        }, 1000);

        this.socket = io(Config.host);
        this.socket.on('edit', () => {
            this.fetchHomework(() => {
                this.setState({isLoading: false})
            });
        });
    }

    parentLogout = () => {
        localStorage.removeItem('isAuth');
        localStorage.removeItem('role');
        localStorage.removeItem('class');
        this.props.history.push('/parent-login');
    }

    onChangeCriteria = (stateName, value) => {
        this.setState({
            isLoading: true,
            [stateName]: value
        }, () => {
            this.fetchHomework(() => {
                this.setState({
                    isLoading: false,
                    openCalendar: false
                })
            });
        });
    };

    toggleCalendar = openCalendar => {
        this.setState({openCalendar})
    }

    render() {
        const { isLoading, school_class, homeworks, defaultSubjects, selectedDate, openCalendar, tabIndex } = this.state;
        const { onChangeCriteria, toggleCalendar, parentLogout } = this;

        return (
            <Fragment>
                <Loader isLoading={isLoading}/>

                <Box display='flex' flexDirection='column' className='blackboard-background white-text view-height-100'>
                    <Box p={1}>
                        <Box boxShadow={1} display='flex' alignItems='center'>
                            <Box>
                                <IconButton className='white-text' onClick={() => parentLogout()}>
                                    <Icon>home</Icon>
                                </IconButton>
                            </Box>
                            <Box mr={2}>&gt;</Box>
                            <Box><b>{school_class.class_name}班家課表</b></Box>  
                        </Box>
                    </Box>

                    {/* date and time */}
                    <Box px={1}>
                        <Box display='flex' className='blackboard-dark-backgroud' onClick={() => toggleCalendar(true)}>
                            <Box py={1} px={1} flexGrow={1} className='datetime-box-text' textAlign='center'>
                                {/* <Box mb={1}>{school_class.class_name}班家課表</Box>   */}
                                <Box>{selectedDate.locale('zh-hk').format('YYYY年MMMDo dddd')}</Box>
                                <Box>{selectedDate.locale('en-es').format('Do, MMMM YYYY')}</Box>
                            </Box>                           
                        </Box>
                    </Box>

                    <DatePicker
                        autoOk
                        className='hidden'
                        open={openCalendar}
                        onClose={() => toggleCalendar(false)}
                        format="YYYY-MM-DD"
                        value={selectedDate}
                        onChange={selectedDate => onChangeCriteria('selectedDate', selectedDate)}
                    />

                    <Box px={2} py={0} display='flex' alignItems='center'>
                        <Box pr={1} textAlign="center" onClick={() => onChangeCriteria('selectedDate', selectedDate.subtract(1, 'days'))}>
                            <Icon className='datatime-box-icon'>arrow_left</Icon>
                        </Box>
                        <Box className='blackboard-tab' flexGrow={1} textAlign='center' justifyContent='center'>
                            今日家課
                            {/* <Tabs value={tabIndex} variant="fullWidth" indicatorColor='secondary' centered onChange={(event, value) => onChangeCriteria('tabIndex', value)}>
                                <Tab className='blackboard-tab' value='create' label='今日家課'/>
                            </Tabs> */}
                        </Box>
                        <Box pl={1} textAlign="center" onClick={() => onChangeCriteria('selectedDate', selectedDate.add(1, 'days'))}>
                            <Icon className='datatime-box-icon'>arrow_right</Icon>
                        </Box>
                    </Box>

                    {/* tabs */}
                    <Box p={2} pt={1} flexGrow={1} flexShrink={1} className='overflow-y-scroll'>
                        {/* homework */}
                        <Box hidden={tabIndex !== 'create'}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                    {defaultSubjects.map((subject, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row" align='center' className='homework-subject-cell homework-cell white-text'>{subject.name}</TableCell>
                                                <TableCell className='homework-cell white-text'>
                                                    <Box display='flex' flexWrap="wrap">
                                                        {homeworks.filter(homework => homework.subject_id === subject.id).map((homework, index) => (
                                                            <Box key={index} mb={1} mr={1} p={1} className='homework-box'>
                                                                {homework.homework_type_name? '['+homework.homework_type_name+']' : ''}{homework.description}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        <TableRow>
                                            <TableCell component="th" scope="row" align='center' className='homework-subject-cell homework-cell white-text'>其他</TableCell>
                                            <TableCell className='homework-cell white-text'>
                                                <Box display='flex' flexWrap="wrap">
                                                    {homeworks.filter(homework => [1,2,3,4].indexOf(homework.subject_id) < 0).map((homework, index) => (
                                                        <Box key={index} mb={1} mr={1} p={1} className='homework-box'>
                                                            {homework.subject_name}: {homework.homework_type_name? '['+homework.homework_type_name+']' : ''}{homework.description}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>                    
                </Box>
            </Fragment>
        )
    }
}

export default ParentBlackboard;