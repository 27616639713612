
import React from 'react';

const AuthContext = React.createContext({
  isAuth: false,
  role: null,
  isAdmin: false,
  chi_name: null,
  eng_name: null,
  classId: null,
  toggleAuth: () => { }
});

export default AuthContext;