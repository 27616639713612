import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const PrivateRoute = ({ component: Component, role, ...rest }) => {
    const context = useContext(AuthContext);
    return (
        <Route {...rest} render={(props) => (
            (context.isAuth === true && context.role === role)
            ? <Component {...props} />
            : <Redirect to='/' />
        )} />
    )
}

export default PrivateRoute;