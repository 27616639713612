// React
import React, { Component, Fragment } from 'react';

// Material ui
import {
	Container,
	Box,
	Fab,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableCell,
	TableSortLabel,
	TableBody,
	TableRow,
	Icon,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	TextField,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

// Helper
import { get, post } from '../../../helpers/ApiHelper';
import { getDate } from '../../../helpers/TimeHelper';

// Moment
import moment from 'moment';

// Lodash
import { orderBy } from 'lodash';

// Component
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import ErrorBox from '../../../components/ErrorBox';
import TableNoData from '../../../components/TableNoData';
import Loader from '../../../components/Loader';

class YearSetting extends Component {
	state = {
		isLoading: true,

		years: [],

		table_columns: [
			{ property: 'name', name: '年度名稱', sort: true },
			{ property: 'start_date', name: '日期', sort: false },
			{ property: '', name: '行動', sort: false },
		],
		sort_column: null,
		sort_order: 'desc',

		open_edit_dialog: false,
		edit_year: {
			id: null,
			name: '',
			start_date: moment(),
			end_date: moment(),
		},
		edit_year_errors: [],

		open_delete_dialog: false,
		delete_id: null,
	};

	fetch = (callback) => {
		let getYear = () =>
			get('getYear').then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					this.setState({ years: result });
				}
			});

		Promise.all([getYear()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	sorting = (new_sort_column = 'name', new_sort_order = 'asc') => {
		const { years, sort_column, sort_order } = this.state;

		if (new_sort_column === sort_column) {
			if (sort_order === 'asc') {
				new_sort_order = 'desc';
			}
		}

		this.setState({ isLoading: true });
		let newYears = orderBy(years, [new_sort_column], [new_sort_order]);

		this.setState({
			years: newYears,
			sort_column: new_sort_column,
			sort_order: new_sort_order,
			isLoading: false,
		});
	};

	componentDidMount = () => {
		this.fetch(() => {
			this.sorting();
		});
	};

	newEditYear = () => {
		return {
			id: null,
			name: '',
			start_date: moment(),
			end_date: moment(),
		};
	};

	handleEditDialog = (status, edit_year = null) => {
		const { newEditYear } = this;

		if (!edit_year) {
			edit_year = newEditYear();
		}

		this.setState({
			open_edit_dialog: status,
			edit_year,
			edit_year_errors: [],
		});
	};

	changeEditYear = (stateName, value) => {
		const { edit_year } = this.state;
		this.setState({ edit_year: { ...edit_year, [stateName]: value } });
	};

	save = () => {
		const { sorting, fetch, newEditYear } = this;
		const { edit_year } = this.state;
		let edit_year_errors = [];

		if (!edit_year.name) {
			edit_year_errors.push('必需輸入年度名稱');
		}

		if (!edit_year.start_date) {
			edit_year_errors.push('必需輸入開始日期');
		}

		if (!edit_year.end_date) {
			edit_year_errors.push('必需輸入結束日期');
		}

		if (edit_year_errors.length > 0) {
			this.setState({ edit_year_errors });
		} else {
			this.setState({ isLoading: true });
			edit_year.start_date = getDate(edit_year.start_date);
			edit_year.end_date = getDate(edit_year.end_date);
			post('editYear', { edit_year }).then(({ status }) => {
				if (status) {
					fetch(() => {
						this.setState(
							{
								isLoading: false,
								open_edit_dialog: false,
								edit_year: newEditYear(),
								edit_year_errors: [],
							},
							() => {
								sorting();
							}
						);
					});
				}
			});
		}
	};

	handleRemoveDialog = (status, id = null) => {
		this.setState({
			open_delete_dialog: status,
			delete_id: id,
		});
	};

	remove = () => {
		const { fetch } = this;
		const { delete_id } = this.state;

		this.setState({ isLoading: true });

		post('deleteYear', { year_id: delete_id }).then(({ status }) => {
			if (status) {
				fetch(() => {
					this.setState({
						isLoading: false,
						open_delete_dialog: false,
						delete_id: null,
					});
				});
			}
		});
	};

	render() {
		const { history } = this.props;
		const {
			isLoading,
			years,
			table_columns,
			sort_column,
			sort_order,
			open_edit_dialog,
			edit_year,
			edit_year_errors,
			open_delete_dialog,
		} = this.state;
		const { sorting, handleEditDialog, changeEditYear, save, handleRemoveDialog, remove } = this;

		return (
			<Fragment>
				<Loader isLoading={isLoading} />

				<div className="root">
					<Nav history={history} title="年度設定" />

					<Container className="root-content">
						<ToolbarBuffer />

						<Box mb={3} display="flex" alignItems="center">
							<Box>
								<Fab
									variant="extended"
									color="primary"
									size="medium"
									onClick={() => handleEditDialog(true)}
								>
									<Icon>add</Icon>新增年度
								</Fab>
							</Box>
						</Box>

						<Box>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											{table_columns.map((column, index) => (
												<TableCell
													key={index}
													className={column.sort ? 'table-header pointer' : 'table-header'}
													align="center"
													onClick={() => {
														column.sort && sorting(column.property);
													}}
												>
													{column.sort && (
														<TableSortLabel
															className="table-sort-label"
															active={sort_column === column.property}
															direction={sort_order}
															hideSortIcon
														/>
													)}
													{column.name}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{years.length > 0 ? (
											years.map((year, index) => (
												<TableRow hover key={index}>
													<TableCell component="th" scope="row" align="center">
														{year.name}
													</TableCell>
													<TableCell align="center">
														{getDate(year.start_date)} - {getDate(year.end_date)}
													</TableCell>
													<TableCell align="center">
														<Box display="flex" justifyContent="center" alignItems="center">
															<Box mr={2}>
																<Fab
																	size="small"
																	color="primary"
																	onClick={() => handleEditDialog(true, year)}
																>
																	<Icon>edit</Icon>
																</Fab>
															</Box>
															<Box>
																<Fab
																	size="small"
																	color="secondary"
																	onClick={() => handleRemoveDialog(true, year.id)}
																>
																	<Icon>delete</Icon>
																</Fab>
															</Box>
														</Box>
													</TableCell>
												</TableRow>
											))
										) : (
											<TableNoData colSpan="3" msg="沒有年度" />
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						<Dialog fullScreen open={open_edit_dialog} onClose={() => handleEditDialog(false)}>
							<AppBar className="dialog-app-bar">
								<Toolbar>
									<IconButton color="inherit" onClick={() => handleEditDialog(false)}>
										<Icon>close</Icon>
									</IconButton>
									<Typography variant="h6" className="dialog-title">
										編輯年份
									</Typography>
								</Toolbar>
							</AppBar>
							<Box mt={3} mx={3}>
								<Container>
									<Box mb={3}>
										<TextField
											fullWidth
											label="年份名稱"
											variant="outlined"
											value={edit_year.name}
											onChange={(event) => changeEditYear('name', event.target.value)}
										/>
									</Box>

									<Box mb={3}>
										<KeyboardDatePicker
											autoOk
											fullWidth
											inputVariant="outlined"
											label="開始日期"
											format="YYYY-MM-DD"
											value={edit_year.start_date}
											onChange={(date) => changeEditYear('start_date', date.format())}
										/>
									</Box>

									<Box mb={3}>
										<KeyboardDatePicker
											autoOk
											fullWidth
											inputVariant="outlined"
											label="結束日期"
											format="YYYY-MM-DD"
											value={edit_year.end_date}
											onChange={(date) => changeEditYear('end_date', date.format())}
										/>
									</Box>

									<Box mb={3} display={edit_year_errors.length > 0 ? 'block' : 'none'}>
										<ErrorBox errors={edit_year_errors} />
									</Box>

									<Box mb={3} display="flex" justifyContent="center" alignItems="center">
										<Box mr={3}>
											<Fab
												variant="extended"
												size="medium"
												onClick={() => handleEditDialog(false)}
											>
												<Icon>close</Icon>取消
											</Fab>
										</Box>
										<Box>
											<Fab
												variant="extended"
												color="primary"
												size="medium"
												onClick={() => save()}
											>
												<Icon>save</Icon>儲存
											</Fab>
										</Box>
									</Box>
								</Container>
							</Box>
						</Dialog>

						<Dialog open={open_delete_dialog} onClose={() => handleRemoveDialog(false)}>
							<DialogTitle>確認刪除年度？</DialogTitle>
							<DialogContent>
								<DialogContentText>一旦刪除，本年度的資料將會一併刪除。</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => handleRemoveDialog(false)}>取消</Button>
								<Button onClick={() => remove()} color="secondary">
									刪除
								</Button>
							</DialogActions>
						</Dialog>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default YearSetting;
