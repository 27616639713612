// React
import React, { Component, Fragment } from 'react';

// Material ui
import {
	Container,
	Box,
	Fab,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Icon,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	TextField,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';

// Helper
import { get, post } from '../../../helpers/ApiHelper';

// Component
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import ErrorBox from '../../../components/ErrorBox';
import TableNoData from '../../../components/TableNoData';
import Loader from '../../../components/Loader';

class SubjectSetting extends Component {
	state = {
		isLoading: true,

		subjects: [],

		table_columns: [
			{ property: 'name', name: '科目' },
			{ property: '', name: '行動' },
		],

		open_edit_dialog: false,
		edit_subject: {
			id: null,
			name: '',
		},
		edit_subject_errors: [],

		open_delete_dialog: false,
		delete_id: null,
	};

	fetch = (callback) => {
		let getSubject = () =>
			get('getSubject').then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					this.setState({ subjects: result });
				}
			});

		Promise.all([getSubject()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	componentDidMount = () => {
		this.fetch(() => {
			this.setState({ isLoading: false });
		});
	};

	newEditSubject = () => {
		return {
			id: null,
			name: '',
		};
	};

	handleEditDialog = (status, edit_subject = null) => {
		const { newEditSubject } = this;

		if (!edit_subject) {
			edit_subject = newEditSubject();
		}

		this.setState({
			open_edit_dialog: status,
			edit_subject,
			edit_subject_errors: [],
		});
	};

	changeEditYear = (stateName, value) => {
		const { edit_subject } = this.state;
		this.setState({ edit_subject: { ...edit_subject, [stateName]: value } });
	};

	save = () => {
		const { fetch, newEditSubject } = this;
		const { edit_subject } = this.state;
		let edit_subject_errors = [];

		if (!edit_subject.name) {
			edit_subject_errors.push('必需輸入科目名稱');
		}

		if (edit_subject_errors.length > 0) {
			this.setState({ edit_subject_errors });
		} else {
			this.setState({ isLoading: true });
			post('editSubject', { edit_subject }).then(({ status }) => {
				if (status) {
					fetch(() => {
						this.setState({
							isLoading: false,
							open_edit_dialog: false,
							edit_subject: newEditSubject(),
							edit_subject_errors: [],
						});
					});
				}
			});
		}
	};

	handleRemoveDialog = (status, id = null) => {
		this.setState({
			open_delete_dialog: status,
			delete_id: id,
		});
	};

	remove = () => {
		const { fetch } = this;
		const { delete_id } = this.state;

		this.setState({ isLoading: true });

		post('deleteSubject', { subject_id: delete_id }).then(({ status }) => {
			if (status) {
				fetch(() => {
					this.setState({
						isLoading: false,
						open_delete_dialog: false,
						delete_id: null,
					});
				});
			}
		});
	};

	render() {
		const { history } = this.props;
		const {
			isLoading,
			subjects,
			table_columns,
			open_edit_dialog,
			edit_subject,
			edit_subject_errors,
			open_delete_dialog,
		} = this.state;
		const { handleEditDialog, changeEditYear, save, handleRemoveDialog, remove } = this;

		return (
			<Fragment>
				<Loader isLoading={isLoading} />

				<div className="root">
					<Nav history={history} title="科目設定" />

					<Container className="root-content">
						<ToolbarBuffer />

						<Box mb={3} display="flex" alignItems="center">
							<Box>
								<Fab
									variant="extended"
									color="primary"
									size="medium"
									onClick={() => handleEditDialog(true)}
								>
									<Icon>add</Icon>新增科目
								</Fab>
							</Box>
						</Box>

						<Box>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											{table_columns.map((column, index) => (
												<TableCell key={index} className="table-header" align="center">
													{column.name}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{subjects.length > 0 ? (
											subjects.map((subject, index) => (
												<TableRow hover key={index}>
													<TableCell component="th" scope="row" align="center">
														{subject.name}
													</TableCell>
													<TableCell align="center">
														<Box display="flex" justifyContent="center" alignItems="center">
															<Box mr={2}>
																<Fab
																	size="small"
																	color="primary"
																	onClick={() => handleEditDialog(true, subject)}
																>
																	<Icon>edit</Icon>
																</Fab>
															</Box>
															<Box>
																<Fab
																	size="small"
																	color="secondary"
																	onClick={() => handleRemoveDialog(true, subject.id)}
																>
																	<Icon>delete</Icon>
																</Fab>
															</Box>
														</Box>
													</TableCell>
												</TableRow>
											))
										) : (
											<TableNoData colSpan="2" msg="沒有科目" />
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						<Dialog fullScreen open={open_edit_dialog} onClose={() => handleEditDialog(false)}>
							<AppBar className="dialog-app-bar">
								<Toolbar>
									<IconButton color="inherit" onClick={() => handleEditDialog(false)}>
										<Icon>close</Icon>
									</IconButton>
									<Typography variant="h6" className="dialog-title">
										編輯科目
									</Typography>
								</Toolbar>
							</AppBar>
							<Box mt={3} mx={3}>
								<Container>
									<Box mb={3}>
										<TextField
											fullWidth
											label="科目名稱"
											variant="outlined"
											value={edit_subject.name}
											onChange={(event) => changeEditYear('name', event.target.value)}
										/>
									</Box>

									<Box mb={3} display={edit_subject_errors.length > 0 ? 'block' : 'none'}>
										<ErrorBox errors={edit_subject_errors} />
									</Box>

									<Box mb={3} display="flex" justifyContent="center" alignItems="center">
										<Box mr={3}>
											<Fab
												variant="extended"
												size="medium"
												onClick={() => handleEditDialog(false)}
											>
												<Icon>close</Icon>取消
											</Fab>
										</Box>
										<Box>
											<Fab
												variant="extended"
												color="primary"
												size="medium"
												onClick={() => save()}
											>
												<Icon>save</Icon>儲存
											</Fab>
										</Box>
									</Box>
								</Container>
							</Box>
						</Dialog>

						<Dialog open={open_delete_dialog} onClose={() => handleRemoveDialog(false)}>
							<DialogTitle>確認刪除科目？</DialogTitle>
							<DialogContent>
								<DialogContentText>一旦刪除，此科目的資料將會一併刪除。</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => handleRemoveDialog(false)}>取消</Button>
								<Button onClick={() => remove()} color="secondary">
									刪除
								</Button>
							</DialogActions>
						</Dialog>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default SubjectSetting;
