import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const ErrorBox = ({errors}) => (
    <Alert severity="error">
        <AlertTitle>錯誤</AlertTitle>
        {errors.map((error, index) => (
            <div key={index}>- {error}</div>
        )) }
    </Alert>
)

export default ErrorBox;