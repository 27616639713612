import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { useGoogleLogin } from '@react-oauth/google';

import { post } from '../helpers/ApiHelper';
import { makeStyles, Container, Typography, Button, Link } from '@material-ui/core';
import Nav from '../components/Nav';
import Loader from '../components/Loader';
import Config from '../const/Config';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing(0, 0, 2, 0),
    minWidth: '40%',
    borderRadius: '20px'
  },
  errorBox: {
    color: 'red'
  }
}));


const roles_to_map = new Map([
  ['teacher', '/course-list'],
  ['student', '/student-dashboard'],
  ['parent', '/parent-blackboard'],
  ['class', '/blackboard'],
  ['class-login', '/class-login']
])

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chosenRole, setChosenRole] = useState('');

  useEffect(() => {
    if (context.isAuth && roles_to_map.has(context.role)) {
      history.push(roles_to_map.get(context.role));
    }
  }, [context, history])

  const login = useGoogleLogin({
    onSuccess: (res) => {
      post(`${encodeURIComponent(chosenRole === 'class-login' ? 'teacher' : chosenRole)}Login`, { token: res.access_token }).then(({ status, result, error }) => {
        if (status) {
          context.toggleAuth({
            isAuth: true,
            role: chosenRole,
            ...result,
            classId: null
          });
          if (roles_to_map.has(chosenRole)) history.push(roles_to_map.get(chosenRole));
        } else {
          setError(error)
          setIsLoading(false);
        }
      }).catch(console.log);
    },
    onNonOAuthError: (err) => {
      console.log(err);
      setChosenRole('');
      setError(err.type === "popup_closed" ? null : '請再試一次')
      setIsLoading(false);
    },
    onError: (err) => {
      console.log(err);
      setError('請再試一次');
      setChosenRole('');
      setIsLoading(false);
    }
  });

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Nav home={true} />
      <main className={classes.root}>
        <Container className={classes.container}>
          <Typography gutterBottom variant="h5">
            {Config.schoolName}家課管理系統
          </Typography>
          {Config.function.teacher &&
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={
                () => {
                  setChosenRole('teacher');
                  setIsLoading(true);
                  login();
                }
              }>
                老師 Google 登入
              </Button>
              <br />
            </Fragment>
          }
          {Config.function.student &&
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={
                () => {
                  setChosenRole('student');
                  setIsLoading(true);
                  login();
                }
              }>
                學生 Google 登入
              </Button>
              <br />
            </Fragment>
          }
          {Config.function.classroom &&
            <Fragment>
              <Button variant="contained" color="primary" size="large" className={classes.button} onClick={
                () => {
                  setChosenRole('class-login');
                  setIsLoading(true);
                  login();
                }
              }>
                老師 Google 登入課室
              </Button>
              <br />
            </Fragment>
          }
          {Config.function.parent &&
            <Button variant="contained" color="primary" size="large" className={classes.button} href="/parent-login" component={Link}>
              家長登入
            </Button>
          }
          <div className={classes.errorBox}>
            {error}
          </div>
        </Container>
      </main>

    </Fragment>
  )
}

export default Home;