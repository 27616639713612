import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MomentUtils from '@date-io/moment';

// theme
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../src/const/Theme';

// datepicker
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/zh-hk';

import Routes from './routes/Routes';
import Config from './const/Config';

function App() {
  return (
    <GoogleOAuthProvider clientId={Config.clientId}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={Theme}>
          <Routes />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </GoogleOAuthProvider >
  );
}

export default App;
