import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

const TableNoData = ({colSpan, msg}) => (
    <TableRow>
        <TableCell colSpan={colSpan} align='center'>
            {msg}
        </TableCell>
    </TableRow>
)

export default TableNoData;