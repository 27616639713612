// React
import React, { Component, Fragment } from "react";

// Socket io
import io from 'socket.io-client';

// Material ui
import { IconButton, Box, Typography, Tabs, Tab, Icon, TableContainer, Table, TableBody, TableRow, TableCell, Fab, Chip, Drawer, Grid, Button } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";

// Helper
import { get, post } from '../../helpers/ApiHelper';
import { getDate, now, time } from '../../helpers/TimeHelper';

// Config
import Config from '../../const/Config';

// Component
import Loader from '../../components/Loader';

class Blackboard extends Component {
  state = {
    isLoading: true,

    school_class: {},
    subjects: [],
    students: [],
    homeworks: [],
    homework_outstandings: [],

    defaultSubjects: [
      { id: 1, name: '中文' },
      { id: 2, name: '英文' },
      { id: 3, name: '數學' },
      { id: 4, name: '常識' }
    ],

    selectedDate: now(),
    openCalendar: false,

    tabIndex: 'create',

    selected_homework: null,
    selected_homework_name: null,
    selected_homework_subject: null,
    open_collect_drawer: false,
    outstanding: []

  }

  fetch = (callback) => {
    const selectedClass = localStorage.getItem('classId');

    let getSubject = () => get('getSubject').then(({ status, result }) => {
      if (status !== false && result !== undefined) {
        this.setState({ subjects: result })
      }
    });

    let getClass = () => get('getOneClass/' + selectedClass).then(({ status, result }) => {
      if (status !== false && result !== undefined) {
        this.setState({ school_class: result.length > 0 ? result[0] : {} })
      }
    });

    let getStudent = () => get('getStudentByClass/' + selectedClass).then(({ status, result }) => {
      if (status !== false && result !== undefined) {
        this.setState({
          students: result,
        })
      }
    });

    Promise.all([getSubject(), getClass(), getStudent()]).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  fetchHomework = (callback) => {
    const { selectedDate, tabIndex } = this.state;
    const selectedClass = localStorage.getItem('classId');

    let getClassHomework = () => get('getClassHomework/' + selectedClass + '/' + tabIndex + '/' + getDate(selectedDate)).then(({ status, result }) => {
      if (status !== false && result !== undefined) {
        this.setState({
          homeworks: result,
        })
      }
    });

    let getOutstanding = () => get('getClassHomeworkOutstanding/' + selectedClass + '/' + tabIndex + '/' + getDate(selectedDate)).then(({ status, result }) => {
      if (status !== false && result !== undefined) {
        this.setState({
          homework_outstandings: result,
        })
      }
    });

    Promise.all([getClassHomework(), getOutstanding()]).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  componentDidMount() {
    this.fetch(() => {
      this.fetchHomework(() => {
        this.setState({ isLoading: false })
      });
    });

    setInterval(() => {
      if (time() === '0:00:00') {
        this.setState({ selectedDate: now() })
      }
    }, 1000);

    this.socket = io(Config.host);
    this.socket.on('edit', () => {
      this.fetchHomework(() => {
        this.setState({ isLoading: false })
      });
    });
  }

  classLogout = () => {
    localStorage.removeItem('isAuth');
    localStorage.removeItem('role');
    localStorage.removeItem('classId');
    window.location.reload();
  }

  onChangeCriteria = (stateName, value) => {
    this.setState({
      isLoading: true,
      [stateName]: value
    }, () => {
      this.fetchHomework(() => {
        this.setState({
          isLoading: false,
          openCalendar: false
        })
      });
    });
  };

  toggleCalendar = openCalendar => {
    this.setState({ openCalendar })
  }

  handleCollectDrawer = (status, selected_homework = null, selected_homework_name = null, selected_homework_subject = null) => {
    const { homework_outstandings } = this.state;
    let outstanding = [];
    if (selected_homework) {
      homework_outstandings.forEach(({ homework_id, student_class_id }, index) => {
        if (homework_id === selected_homework) {
          outstanding.push(student_class_id);
        }
      })
    }

    this.setState({
      selected_homework,
      selected_homework_name,
      selected_homework_subject,
      'open_collect_drawer': status,
      outstanding,

    });
  }

  selectStudent = (id) => {
    const { outstanding } = this.state;
    const index = outstanding.indexOf(id);
    if (index !== -1) {
      outstanding.splice(index, 1);
    } else {
      outstanding.push(id);
    }
    this.setState({ outstanding });
  }

  isSelected = (id) => {
    const { outstanding } = this.state;
    const index = outstanding.indexOf(id);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  save = () => {
    const { fetchHomework } = this;
    const { selected_homework, outstanding } = this.state;

    const data = {
      homework_id: selected_homework,
      outstanding
    }

    this.setState({ isLoading: true });
    post('collectHomework', data).then(({ status }) => {
      if (status) {
        fetchHomework(() => {
          this.setState({
            isLoading: false,
            selected_homework: null,
            'open_collect_drawer': false,
            outstanding: [],
          });
        })
      }
    })
  }

  render() {
    const { isLoading, school_class, students, homeworks, homework_outstandings, defaultSubjects, selectedDate, openCalendar, tabIndex, selected_homework_name, selected_homework_subject, open_collect_drawer, outstanding } = this.state;
    const { onChangeCriteria, toggleCalendar, handleCollectDrawer, selectStudent, isSelected, save, classLogout } = this;

    return (
      <Fragment>
        <Loader isLoading={isLoading} />

        <Box display='flex' flexDirection='column' className='blackboard-background white-text view-height-100'>
          <Box p={1}>
            <Box boxShadow={1} display='flex' alignItems='center'>
              <Box>
                <IconButton className='white-text' onClick={() => classLogout()}>
                  <Icon>home</Icon>
                </IconButton>
              </Box>
              <Box mr={2}>&gt;</Box>
              <Box><b>{school_class.class_name}班家課表</b></Box>
            </Box>
          </Box>

          {/* date and time */}
          <Box px={1}>
            <Box display='flex' className='blackboard-dark-backgroud' onClick={() => toggleCalendar(true)}>
              <Box py={1} px={1} flexGrow={1} className='datetime-box-text' textAlign='center'>
                {/* <Box mb={1}>{school_class.class_name}班家課表</Box>   */}
                <Box>{selectedDate.locale('zh-hk').format('YYYY年MMMDo dddd')}</Box>
                <Box>{selectedDate.locale('en-es').format('Do, MMMM YYYY')}</Box>
              </Box>
            </Box>
          </Box>

          <DatePicker
            autoOk
            className='hidden'
            open={openCalendar}
            onClose={() => toggleCalendar(false)}
            format="YYYY-MM-DD"
            value={selectedDate}
            onChange={selectedDate => onChangeCriteria('selectedDate', selectedDate)}
          />

          <Box px={2} py={0} display='flex' alignItems='center'>
            <Box pr={1} textAlign="center" onClick={() => onChangeCriteria('selectedDate', selectedDate.subtract(1, 'days'))}>
              <Icon className='datatime-box-icon'>arrow_left</Icon>
            </Box>
            <Box px={1} flexGrow={1} textAlign='center' justifyContent='center'>
              <Tabs value={tabIndex} variant="fullWidth" indicatorColor='secondary' centered onChange={(event, value) => onChangeCriteria('tabIndex', value)}>
                <Tab className='blackboard-tab' value='create' label='今日家課' />
                <Tab className='blackboard-tab' value='submit' label='點收' />
              </Tabs>
            </Box>
            <Box pl={1} textAlign="center" onClick={() => onChangeCriteria('selectedDate', selectedDate.add(1, 'days'))}>
              <Icon className='datatime-box-icon'>arrow_right</Icon>
            </Box>
          </Box>

          {/* tabs */}
          <Box p={1} pt={1} flexGrow={1} flexShrink={1} className='overflow-y-scroll'>
            {/* homework */}
            <Box hidden={tabIndex !== 'create'}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {defaultSubjects.map((subject, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" align='center' className='homework-subject-cell homework-cell white-text'>{subject.name}</TableCell>
                        <TableCell className='homework-cell white-text'>
                          <Box display='flex' flexWrap="wrap">
                            {homeworks.filter(homework => homework.subject_id === subject.id).map((homework, index) => (
                              <Box key={index} mb={1} mr={1} p={1} className='homework-box'>
                                {homework.homework_type_name ? '[' + homework.homework_type_name + ']' : ''}{homework.description}
                              </Box>
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell component="th" scope="row" align='center' className='homework-subject-cell homework-cell white-text'>其他</TableCell>
                      <TableCell className='homework-cell white-text'>
                        <Box display='flex' flexWrap="wrap">
                          {homeworks.filter(homework => [1, 2, 3, 4].indexOf(homework.subject_id) < 0).map((homework, index) => (
                            <Box key={index} mb={1} mr={1} p={1} className='homework-box'>
                              {homework.subject_name}: {homework.homework_type_name ? '[' + homework.homework_type_name + ']' : ''}{homework.description}
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {/* collect */}
            <Box className='full-height' hidden={tabIndex !== 'submit'}>
              <Box className='full-height' display='flex' flexDirection='column'>
                {homeworks.length > 0 ?
                  <Fragment>
                    <Box flexGrow={1} flexShrink={1} className='overflow-y-scroll'>
                      {homeworks.map((homework, index) => (
                        <Box p={1} mb={1} boxShadow={1} key={index} display='flex' alignItems='center' className='blackboard-dark-backgroud'>
                          <Box px={2}>
                            <Typography variant="h5">
                              {index + 1}
                            </Typography>
                          </Box>
                          <Box flexGrow='1' borderLeft={1} pl={2} alignItems='center'>
                            <Box mb={2} className='collect-box-text'>{homework.subject_name}: {homework.homework_type_name ? `[${homework.homework_type_name}]` : ''}{homework.description}</Box>
                            {homework.collected ?
                              homework_outstandings.filter(outstnding => outstnding.homework_id === homework.id).length > 0 ?
                                <Box display='flex' flexWrap='wrap' alignContent='flex-start' mt={2}>
                                  {homework_outstandings.filter(outstanding => outstanding.homework_id === homework.id).map((outstanding, index) => (
                                    <Chip key={index} className='collect-box-chip' color='secondary' label={outstanding.class_number} />
                                  ))}
                                </Box>
                                :
                                <span>已全部遞交</span>
                              :
                              <span>未進行點收</span>
                            }
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box display='flex' alignItems='center'>
                      <Box flexWrap='nowrap' pr={1}>
                        <Typography variant="h6">
                          點收:
                        </Typography>
                      </Box>
                      <Box display='flex' flexGrow={2} flexWrap='wrap'>
                        {homeworks.map((homework, index) => (
                          <Box pr={1} pt={1} pb={1} key={index}>
                            <Fab color="primary" size="small" onClick={() => handleCollectDrawer(true, homework.id, homework.description, homework.subject_name)}>
                              {index + 1}
                            </Fab>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Fragment>
                  :
                  <Box p={2} mt={5} textAlign='center'>
                    <Box className='collect-box-text'>今天沒有需要點收之家課</Box>
                  </Box>
                }
              </Box>
            </Box>

            {/* collect homework drawer */}
            <Drawer anchor="bottom" open={open_collect_drawer} onClose={() => handleCollectDrawer(false)}>
              <Box p={2}>
                <Box mb={3}>
                  <Typography variant="h6">
                    {selected_homework_subject} - {selected_homework_name}
                  </Typography>
                  <Typography variant="h6">
                    請選擇<u className='red-text'><b>欠交</b></u>同學：
                  </Typography>
                </Box>
                {students.length > 0 ?
                  <Box>
                    <Box mb={1} textAlign='center'>
                      已選擇 {outstanding.length} 人
                    </Box>
                    <Box mb={3} textAlign='center'>
                      (如沒有欠交同學，請直接按儲存)
                    </Box>
                    <Box mb={1} display='flex' justifyContent='center' alignItems='center'>
                      <Grid container spacing={3}>
                        {students.map((student, index) => (
                          <Grid key={index} item xs={3}>
                            <Box p={1} textAlign='center' borderRadius={10} className={isSelected(student.id) ? 'check-box' : 'uncheck-box'} onClick={() => selectStudent(student.id)}>
                              {student.class_number} {student.chi_name || student.eng_name}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                  :
                  <Box mb={3}>沒有學生資料</Box>
                }
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Button onClick={() => handleCollectDrawer(false)} >取消</Button>
                  <Button onClick={() => save()} color="secondary">儲存</Button>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Box>
      </Fragment>
    )
  }
}

export default Blackboard;