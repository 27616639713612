import Config from '../const/Config';

// local
const domain = Config.host;

// get method
export const get = (method, params = []) => {
    let paramText = '';
    for(let i = 0; i < params.length; i++) {
        paramText += '/' + params[i];
    }

    return fetch(domain + '/' + method + paramText, {
        method: 'GET',
        credentials: 'include'
    }).then(response => {
        return response.json();
    }).then(response => {
        return response; 
    })
}

// post method
export const post = (method, data = {}) => {
    return fetch(domain + '/' + method, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', 'Keep-Alive': 'timeout=500' },
        credentials: 'include'
    }).then((response) => {
        return response.json();
    }).then((response) => {
        return response;
    })
}