import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 9999,
		color: '#fff',
	},
}));

const Loading = ({isLoading}) => {
    const classes = useStyles();
	return (
		<div>
			<Backdrop className={classes.backdrop} open={isLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}

export default Loading;