// React
import React, { Component, Fragment } from 'react';

// Material ui
import {
	Hidden,
	Container,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Fab,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableCell,
	TableSortLabel,
	TableBody,
	TableRow,
	Icon,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

// Helper
import { get, post } from '../../../helpers/ApiHelper';
import { getCurrentYearId } from '../../../helpers/TimeHelper';
import { excelTemplate } from '../../../helpers/ExcelHelper';

// Lodash
import { map, uniq, uniqBy, find, orderBy, filter, sortBy } from 'lodash';

// import XLSX
import XLSX from 'xlsx';

// Component
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import ErrorBox from '../../../components/ErrorBox';
import TableNoData from '../../../components/TableNoData';
import Loader from '../../../components/Loader';

class StudentSetting extends Component {
	state = {
		isLoading: true,

		years: [],
		classNames: [],
		students: [],
		showStudents: [],

		selectedYear: '',
		selectedClass: 'allClass',

		table_columns: [
			{ property: 'class_name', name: '班別', sort: true },
			{ property: 'class_number', name: '學號', sort: true },
			{ property: 'chi_name', name: '學生', sort: true },
			{ property: 'email', name: '電郵', sort: true },
		],
		sort_column: null,
		sort_order: 'desc',

		open_edit_dialog: false,
		student_file: [],
		edit_student_errors: [],

		header_format: ['學生編號', '班別', '學號', '中文姓名', '英文姓名', '電郵'],
	};

	fetchYear = (callback) => {
		let getYear = () =>
			get('getYear').then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					const selectedYear = getCurrentYearId(result);
					this.setState({
						years: result,
						selectedYear,
					});
				}
			});

		Promise.all([getYear()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	fetch = (callback) => {
		const { selectedYear } = this.state;

		let getStudent = () =>
			get('getStudentByYear/' + selectedYear).then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					this.setState({
						students: result,
						showStudents: result,
						classNames: uniq(map(sortBy(result, ['class_name']), 'class_name')),
					});
				}
			});

		Promise.all([getStudent()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	sorting = (new_sort_column = 'class_name', new_sort_order = 'asc') => {
		const { showStudents, sort_column, sort_order } = this.state;

		if (new_sort_column === sort_column) {
			if (sort_order === 'asc') {
				new_sort_order = 'desc';
			}
		}

		this.setState({ isLoading: true });
		let newStudents = orderBy(showStudents, [new_sort_column], [new_sort_order]);

		this.setState({
			showStudents: newStudents,
			sort_column: new_sort_column,
			sort_order: new_sort_order,
			isLoading: false,
		});
	};

	componentDidMount = () => {
		this.fetchYear(() => {
			this.fetch(() => {
				this.sorting();
			});
		});
	};

	onChangeYear = (selectedYear) => {
		this.setState(
			{
				isLoading: true,
				selectedYear,
			},
			() => {
				this.fetch(() => {
					this.setState({ isLoading: false });
				});
			}
		);
	};

	onChangeClass = (selectedClass) => {
		this.setState(
			{
				selectedClass,
				showStudents:
					selectedClass === 'allClass'
						? this.state.students
						: filter(this.state.students, { class_name: selectedClass }),
			},
			() => {
				this.sorting();
			}
		);
	};

	handleEditDialog = (status) => {
		this.setState({
			open_edit_dialog: status,
			student_file: [],
			edit_student_errors: [],
		});
	};

	onUploadFile = (student_file) => {
		this.setState({ student_file });
	};

	save = () => {
		const { fetch, sorting } = this;
		const { selectedYear, student_file, header_format } = this.state;
		let edit_student_errors = [];

		if (student_file.length === 0) {
			edit_student_errors.push('必需以XLSX格式上載學生資料');
		}

		if (edit_student_errors.length > 0) {
			this.setState({ edit_student_errors });
		} else {
			const file = student_file[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				this.setState({ isLoading: true });

				const workbook = XLSX.read(e.target.result, { type: 'array' });
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];

				// [student_key, class, class_no, chi_name, eng_name, email]
				const header_data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
				const header = header_data[0];

				if (JSON.stringify(header) !== JSON.stringify(header_format)) {
					edit_student_errors.push('請參照範本格式上載學生資料');
					this.setState({
						edit_student_errors,
						isLoading: false,
					});
				} else {
					const student_data = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 1, blankrows: false });
					const edit_student = {
						year_id: selectedYear,
						class_data: uniqBy(
							map(student_data, (data) => {
								return {
									year_id: selectedYear,
									name: data[1],
								};
							})
						),
						student_data: map(student_data, (data) => {
							return {
								student_key: data[0],
								class: data[1],
								class_number: data[2],
								chi_name: data[3],
								eng_name: data[4],
								email: data[5],
							};
						}),
					};

					post('editStudent', { edit_student }).then(({ status }) => {
						if (!status) {
						}

						fetch(() => {
							this.setState(
								{
									open_edit_dialog: false,
									student_file: [],
									edit_student_errors: [],
								},
								() => {
									sorting();
								}
							);
						});
					});
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	download = () => {
		let { years, selectedYear, header_format } = this.state;
		let title = find(years, { id: selectedYear }).name + '學生資料';
		let data = [header_format];

		map(this.state.students, ({ student_key, class_name, class_number, chi_name, eng_name, email }) => {
			data.push([student_key, class_name, class_number, chi_name, eng_name, email]);
		});
		excelTemplate(title, data);
	};

	render() {
		const { history } = this.props;
		const {
			isLoading,
			years,
			classNames,
			showStudents,
			selectedYear,
			selectedClass,
			table_columns,
			sort_column,
			sort_order,
			open_edit_dialog,
			edit_student_errors,
		} = this.state;
		const { sorting, onChangeYear, onChangeClass, handleEditDialog, onUploadFile, save, download } = this;

		return (
			<Fragment>
				<Loader isLoading={isLoading} />

				<div className="root">
					<Nav history={history} title="學生設定" />

					<Container className="root-content">
						<ToolbarBuffer />

						<Box mb={3} display="flex" alignItems="center">
							<Box mr={3}>
								<FormControl variant="outlined">
									<InputLabel id="year">年度</InputLabel>
									<Select
										labelId="year"
										value={selectedYear}
										labelWidth={35}
										onChange={(event) => onChangeYear(event.target.value)}
									>
										{years.map((year, index) => (
											<MenuItem key={index} value={year.id}>
												{year.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>

							<Hidden smDown>
								<Box mr={3}>
									<Fab
										variant="extended"
										color="primary"
										size="medium"
										onClick={() => handleEditDialog(true)}
									>
										<Icon className="margin-right-10">cloud_upload</Icon>更新學生資料
									</Fab>
								</Box>

								<Box>
									<Fab
										variant="extended"
										color="primary"
										size="medium"
										onClick={() => download(true)}
									>
										<Icon className="margin-right-10">cloud_download</Icon>下載學生範本
									</Fab>
								</Box>
							</Hidden>

							<Hidden mdUp>
								<Box>請以電腦進行更新</Box>
							</Hidden>
						</Box>

						<Box mb={3} display="flex" alignItems="center">
							<Box mr={3}>
								<FormControl variant="outlined">
									<InputLabel id="class">班別</InputLabel>
									<Select
										labelId="class"
										value={selectedClass}
										labelWidth={35}
										onChange={(event) => onChangeClass(event.target.value)}
									>
										<MenuItem value="allClass">全部班別</MenuItem>
										{classNames.map((class_name, index) => (
											<MenuItem key={index} value={class_name}>
												{class_name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Box>

						<Box>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											{table_columns.map((column, index) => (
												<TableCell
													key={index}
													className={column.sort ? 'table-header pointer' : 'table-header'}
													align="center"
													onClick={() => {
														column.sort && sorting(column.property);
													}}
												>
													{column.sort && (
														<TableSortLabel
															className="table-sort-label"
															active={sort_column === column.property}
															direction={sort_order}
															hideSortIcon
														/>
													)}
													{column.name}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{showStudents.length > 0 ? (
											showStudents.map((student, index) => (
												<TableRow hover key={index}>
													<TableCell component="th" scope="row" align="center">
														{student.class_name}
													</TableCell>
													<TableCell align="center">{student.class_number}</TableCell>
													<TableCell align="center">{student.chi_name}</TableCell>
													<TableCell align="center">{student.email}</TableCell>
												</TableRow>
											))
										) : (
											<TableNoData colSpan="4" msg="沒有學生" />
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						{/* edit student dialog */}
						<Dialog fullScreen open={open_edit_dialog} onClose={() => handleEditDialog(false)}>
							<AppBar className="dialog-app-bar">
								<Toolbar>
									<IconButton color="inherit" onClick={() => handleEditDialog(false)}>
										<Icon>close</Icon>
									</IconButton>
									<Typography variant="h6" className="dialog-title">
										更新學生資料
									</Typography>
								</Toolbar>
							</AppBar>
							<Box mt={3} mx={3}>
								<Container>
									<Box mb={3}>
										<FormControl variant="outlined" fullWidth>
											<InputLabel id="class">年度</InputLabel>
											<Select
												labelId="class"
												value={selectedYear}
												labelWidth={35}
												onChange={(event) => onChangeYear(event.target.value)}
											>
												{years.map((year, index) => (
													<MenuItem key={index} value={year.id}>
														{year.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>

									<Box mb={3} textAlign="center">
										<DropzoneArea
											dropzoneClass="dropzone"
											dropzoneText="請以XLSX格式上載學生資料"
											useChipsForPreview
											acceptedFiles={[
												'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
											]}
											filesLimit={1}
											onChange={(student_file) => onUploadFile(student_file)}
										/>
									</Box>

									<Box mb={3} display={edit_student_errors.length > 0 ? 'block' : 'none'}>
										<ErrorBox errors={edit_student_errors} />
									</Box>

									<Box display="flex" justifyContent="center" alignItems="center">
										<Box mr={3}>
											<Fab
												variant="extended"
												size="medium"
												onClick={() => handleEditDialog(false)}
											>
												<Icon>close</Icon>取消
											</Fab>
										</Box>
										<Box>
											<Fab
												variant="extended"
												color="primary"
												size="medium"
												onClick={() => save()}
											>
												<Icon>save</Icon>儲存
											</Fab>
										</Box>
									</Box>
								</Container>
							</Box>
						</Dialog>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default StudentSetting;
