// React
import React, { Fragment, useState, useEffect } from 'react';

// import context
import AuthContext from '../context/AuthContext';

// React router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Auth
import PrivateRoute from './PrivateRoute';

// import ApiHelper
import { post } from '../helpers/ApiHelper';

// Container
import CourseList from '../containers/teacher/CourseList';
import ClassHomework from '../containers/teacher/ClassHomework';
import SubjectHomework from '../containers/teacher/SubjectHomework';

import YearSetting from '../containers/teacher/setting/YearSetting';
import TeacherSetting from '../containers/teacher/setting/TeacherSetting';
import TeacherSubjectSetting from '../containers/teacher/setting/TeacherSubjectSetting';
import StudentSetting from '../containers/teacher/setting/StudentSetting';
import SubjectSetting from '../containers/teacher/setting/SubjectSetting';
import HomeworkTypeSetting from '../containers/teacher/setting/HomeworkTypeSetting';

import StudentDashboard from '../containers/student/StudentDashboard';
import Outstanding from '../containers/student/Outstanding';

import ClassLogin from '../containers/class/ClassLogin';
import Blackboard from '../containers/class/Blackboard';

import ParentLogin from '../containers/parent/ParentLogin';
import ParentBlackboard from '../containers/parent/ParentBlackboard';

import Home from '../containers/Home';

import Loader from '../components/Loader';

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState();
  const [role, setRole] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [chi_name, setChi_name] = useState();
  const [eng_name, setEng_name] = useState();
  const [classId, setClassId] = useState();

  useEffect(() => {
    const auth = async () => {
      const role = localStorage.getItem('role');
      const classId = localStorage.getItem('classId');

      if (role === 'teacher' || role === 'student') {
        const { status, result } = await post('auth');
        if (status) await toggleAuth({
          isAuth: true,
          role,
          isAdmin: result.isAdmin,
          chi_name: result.chi_name,
          eng_name: result.eng_name,
          classId: null
        });
        return setIsLoading(false);
      }

      if (role === 'class' || role === 'parent') {
        await toggleAuth({
          isAuth: true,
          role,
          isAdmin: false,
          chi_name: null,
          eng_name: null,
          classId: classId
        });
        return setIsLoading(false);
      }

      await toggleAuth({});
      return setIsLoading(false);
    };
    auth();
  }, []);

  const toggleAuth = ({
    isAuth = false,
    role = null,
    isAdmin = false,
    chi_name = null,
    eng_name = null,
    classId = null
  }) => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('role', role);
      localStorage.setItem('classId', classId);
      setIsAuth(isAuth);
      setRole(role);
      setIsAdmin(isAdmin);
      setChi_name(chi_name);
      setEng_name(eng_name);
      setClassId(classId);
      resolve();
    });
  };

  const authContextValue = { isAuth, role, isAdmin, chi_name, eng_name, classId, toggleAuth };

  return (
    <Fragment>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <AuthContext.Provider value={authContextValue}>
          <Router>
            <Switch>
              <PrivateRoute exact path="/course-list" component={CourseList} role="teacher" />
              <PrivateRoute
                exact
                path="/class-homework/:class_id"
                component={ClassHomework}
                role="teacher"
              />
              <PrivateRoute
                exact
                path="/subject-homework/:class_id/:subject_id"
                component={SubjectHomework}
                role="teacher"
              />

              <PrivateRoute exact path="/year-setting" component={YearSetting} role="teacher" />
              <PrivateRoute exact path="/teacher-setting" component={TeacherSetting} role="teacher" />
              <PrivateRoute
                exact
                path="/teacher-subject-setting"
                component={TeacherSubjectSetting}
                role="teacher"
              />
              <PrivateRoute exact path="/student-setting" component={StudentSetting} role="teacher" />
              <PrivateRoute exact path="/subject-setting" component={SubjectSetting} role="teacher" />
              <PrivateRoute
                exact
                path="/homework-type-setting"
                component={HomeworkTypeSetting}
                role="teacher"
              />

              <PrivateRoute exact path="/student-dashboard" component={StudentDashboard} role="student" />
              <PrivateRoute exact path="/outstanding" component={Outstanding} role="student" />

              <PrivateRoute exact path="/class-login" component={ClassLogin} role="class-login" />
              <PrivateRoute exact path="/blackboard" component={Blackboard} role="class" />

              <Route exact path="/parent-login" component={ParentLogin} />
              <PrivateRoute exact path="/parent-blackboard" component={ParentBlackboard} role="parent" />

              <Route path="/" component={Home} />
            </Switch>
          </Router>
        </AuthContext.Provider>
      )}
    </Fragment>
  );
};

export default Routes;
