import React, { Fragment, useState, useEffect } from "react";
import { Container, Box, FormControl, InputLabel, Select, MenuItem, Grid, Tabs, Tab, Icon, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Nav from '../../components/Nav';
import Loader from '../../components/Loader';
import ToolbarBuffer from '../../components/ToolbarBuffer';
import { get } from '../../helpers/ApiHelper';
import { getCurrentYearId } from '../../helpers/TimeHelper';
import { getDate, now, yesterday, tomorrow } from '../../helpers/TimeHelper';
import { KeyboardDatePicker } from '@material-ui/pickers';

const CourseList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [student, setStudent] = useState(null);
  const [studentClassId, setStudentClassId] = useState(null)
  const [tabIndex, setTabIndex] = useState('create');
  const [selectedDate, setSelectedDate] = useState(now());
  const [classId, setClassId] = useState(null);
  const [homeworks, setHomeworks] = useState([]);
  const [outstandings, setOutstandings] = useState([]);

  useEffect(() => {
    const fetchYear = async () => {
      const { status, result } = await get('getYear');
      if (status !== false && result !== undefined) {
        setYears(result)
        setSelectedYear(getCurrentYearId(result))
      }
    }
    fetchYear();
  }, [])

  useEffect(() => {
    const fetchStudent = async () => {
      const { status, result } = await get('getOneStudentByYear/' + selectedYear);
      if (status !== false && result.length > 0) {
        setStudent(result[0]);
        setClassId(result[0].class_id);
        setStudentClassId(result[0].student_class_id);
      } else {
        setStudent(null);
        setClassId(null);
        setStudentClassId(null);
        setHomeworks([]);
        setIsLoading(false);
      }
    }
    if (selectedYear) fetchStudent();
  }, [selectedYear])

  useEffect(() => {
    const fetchHomework = async () => {
      const { status, result } = await get('getClassHomework/' + classId + '/' + tabIndex + '/' + getDate(selectedDate));
      if (status !== false && result !== undefined) {
        setHomeworks(result);
      } else {
        setHomeworks([]);
        setIsLoading(false);
      }
    }
    if (classId && selectedDate && tabIndex) {
      fetchHomework();
    } else {
      setHomeworks([]);
      setIsLoading(false);
    }
  }, [classId, selectedDate, tabIndex])

  useEffect(() => {
    const fetchOutstanding = async () => {
      const { status, result } = await get('getPersonalOutstanding/' + classId + '/' + tabIndex + '/' + getDate(selectedDate) + '/' + studentClassId);
      if (status !== false && result !== undefined) {
        setOutstandings(result);
      } else {
        setOutstandings([]);
      }
      setIsLoading(false);
    }

    if (classId && selectedDate && tabIndex && homeworks.length > 0) {
      fetchOutstanding();
    } else {
      setOutstandings([]);
      setIsLoading(false);
    }
  }, [classId, selectedDate, tabIndex, studentClassId, homeworks])

  const onChangeYear = (selectedYear) => {
    setIsLoading(true);
    setSelectedYear(selectedYear);
  }

  const onChangeTabIndex = (value) => {
    setIsLoading(true);
    setTabIndex(value);
  }

  const onChangeDate = (value) => {
    setIsLoading(true);
    setSelectedDate(value)
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />

      <div className='root'>
        <Nav title='電子手冊' />

        <Container className='root-content'>
          <ToolbarBuffer />

          <Box mb={3} display='flex' alignItems='center'>
            <Box mr={3}>
              <FormControl variant="outlined">
                <InputLabel id="class">年度</InputLabel>
                <Select labelId="class" value={selectedYear} labelWidth={35} onChange={event => onChangeYear(event.target.value)}>
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year.id}>{year.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              {student ?
                <Fragment>
                  {student.class_name} {student.chi_name || student.eng_name} ({student.class_number})
                </Fragment>
                :
                "未有學生資料"
              }
            </Box>
          </Box>

          {/* homework list */}
          <Box mb={4}>
            <Tabs value={tabIndex} indicatorColor='primary' textColor='primary' onChange={(event, value) => onChangeTabIndex(value)}>
              <Tab value='create' label='今日派發' />
              <Tab value='submit' label='今日遞交' />
            </Tabs>
          </Box>

          {/* date picker */}
          <Box mb={4} display='flex' alignItems='center'>
            <Box>
              <Icon onClick={() => onChangeDate(yesterday(selectedDate))}>arrow_back_ios</Icon>
            </Box>
            <Box ml={1} mr={2}>
              <KeyboardDatePicker autoOk inputVariant="outlined" label="日期" format="YYYY-MM-DD" value={selectedDate} onChange={selectedDate => onChangeDate(selectedDate.format())} />
            </Box>
            <Box>
              <Icon onClick={() => onChangeDate(tomorrow(selectedDate))}>arrow_forward_ios</Icon>
            </Box>
          </Box>

          <Box>
            {homeworks.length > 0 ?
              homeworks.map((homework, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary className='expand-header expand-padding'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display='flex' alignItems='center'>
                          <Icon className='margin-right-10 green-text'>description</Icon>
                          <b>
                            {homework.subject_name}: {homework.homework_type_name ? '[' + homework.homework_type_name + ']' : ''}{homework.description}
                          </b>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box display='flex' alignItems='center'>
                          <Icon className='margin-right-10'>alarm</Icon>
                          <b>{getDate(homework.submit_date)}</b>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box display='flex' alignItems='center'>
                          <Icon className='margin-right-10'>assignment_ind</Icon>
                          {Boolean(homework.collected) ?
                            outstandings.find(outstanding => outstanding.homework_id === homework.id) ?
                              <span className='red-text'>欠交</span>
                              :
                              <span className='green-text'>已遞交</span>
                            :
                            "未點收"

                          }
                        </Box>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className='expand-padding'>
                    <Box px={1} py={1} flex={1}>
                      <Box mb={2}>
                        <b className='margin-right-5'>科目:</b>
                        {homework.subject_name}
                      </Box>
                      <Box mb={2}>
                        <b className='margin-right-5'>種類:</b>
                        {homework.homework_type_name ? homework.homework_type_name : '-'}
                      </Box>
                      <Box mb={2}>
                        <b className='margin-right-5'>描述:</b>
                        {homework.description}
                      </Box>
                      <Box mb={2}>
                        <b className='margin-right-5'>派發日期:</b>
                        {getDate(homework.create_date)}
                      </Box>
                      <Box mb={2}>
                        <b className='margin-right-5'>遞交日期:</b>
                        {getDate(homework.submit_date)}
                      </Box>
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
              :
              <Box>
                <Alert severity="info">沒有功課記錄</Alert>
              </Box>
            }
          </Box>
        </Container>
      </div>
    </Fragment>
  )

}
export default CourseList;