import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Container,
	Icon,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	Paper,
	Typography,
	Chip,
} from '@material-ui/core';
import { get } from '../../helpers/ApiHelper';
import { getCurrentYearId, tomorrow, yesterday } from '../../helpers/TimeHelper';
import { getDate, now } from '../../helpers/TimeHelper';
import { map, filter } from 'lodash';
import Nav from '../../components/Nav';
import Loader from '../../components/Loader';
import ToolbarBuffer from '../../components/ToolbarBuffer';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const CourseList = () => {
	const theme = useTheme();
	const matchXsDown = useMediaQuery(theme.breakpoints.down('xs'));

	const [isLoading, setIsLoading] = useState(true);
	const [years, setYears] = useState([]);
	const [teacher_classes, setTeacher_classes] = useState([]);
	const [teacher_subjects, setTeacher_subjects] = useState([]);
	const [class_homeworks, setClass_homeworks] = useState([]);
	const [subject_homeworks, setSubject_homeworks] = useState([]);
	const [selectedYear, setSelectedYear] = useState('');
	const [selectedDate, setSelectedDate] = useState(now());

	useEffect(() => {
		const fetchYear = async () => {
			const { status, result } = await get('getYear');
			if (status !== false && result !== undefined) {
				setYears(result);
				setSelectedYear(getCurrentYearId(result));
			}
		};
		fetchYear();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			if (selectedYear) {
				const formattedDate = getDate(selectedDate);

				const teacherClass = await get('getPersonalTeacherClassByYear/' + selectedYear);
				if (teacherClass.status !== false && teacherClass.result !== undefined)
					setTeacher_classes(teacherClass.result);

				const teacherSubject = await get('getPersonalTeacherSubjectByYear/' + selectedYear);
				if (teacherSubject.status !== false && teacherSubject.result !== undefined)
					setTeacher_subjects(teacherSubject.result);

				const classHomework = await get(
					'getPersonalTeacherClassHomeworkByYear/submit/' + selectedYear + '/' + formattedDate
				);
				if (classHomework.status !== false && classHomework.result !== undefined)
					setClass_homeworks(classHomework.result);

				const subjectHomework = await get(
					'getPersonalTeacherSubjectHomeworkByYear/submit/' + selectedYear + '/' + formattedDate
				);
				if (subjectHomework.status !== false && subjectHomework.result !== undefined)
					setSubject_homeworks(subjectHomework.result);
			}
			setIsLoading(false);
		};

		fetchData();
	}, [selectedYear, selectedDate]);

	const onChangeYear = (selectedYear) => {
		setSelectedYear(selectedYear);
	};

	return (
		<Fragment>
			<Loader isLoading={isLoading} />

			<div className="root">
				<Nav title="課程列表" />

				<Container className="root-content">
					<ToolbarBuffer />

					<Box
						mb={4}
						display="flex"
						alignItems="center"
						justifyContent={matchXsDown ? 'center' : 'flex-start'}
					>
						<Icon onClick={() => setSelectedDate(yesterday(selectedDate))}>arrow_back_ios</Icon>
						<Box mx={2} display="flex" flexDirection={matchXsDown ? 'column' : 'row'} alignItems="center">
							<FormControl variant="outlined" fullWidth={matchXsDown}>
								<InputLabel id="class">年度</InputLabel>
								<Select
									labelId="class"
									value={selectedYear}
									labelWidth={35}
									onChange={(event) => onChangeYear(event.target.value)}
								>
									{years.map((year, index) => (
										<MenuItem key={index} value={year.id}>
											{year.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Box m={1} />
							<KeyboardDatePicker
								autoOk
								inputVariant="outlined"
								label="日期"
								format="YYYY-MM-DD"
								value={selectedDate}
								onChange={(selectedDate) => setSelectedDate(selectedDate.format())}
							/>
						</Box>
						<Icon onClick={() => setSelectedDate(tomorrow(selectedDate))}>arrow_forward_ios</Icon>
					</Box>

					{/*<Box mb={3} display="flex" alignItems="center">*/}
					{/*	<Box mr={3}>*/}
					{/*		<FormControl variant="outlined">*/}
					{/*			<InputLabel id="class">年度</InputLabel>*/}
					{/*			<Select*/}
					{/*				labelId="class"*/}
					{/*				value={selectedYear}*/}
					{/*				labelWidth={35}*/}
					{/*				onChange={(event) => onChangeYear(event.target.value)}*/}
					{/*			>*/}
					{/*				{years.map((year, index) => (*/}
					{/*					<MenuItem key={index} value={year.id}>*/}
					{/*						{year.name}*/}
					{/*					</MenuItem>*/}
					{/*				))}*/}
					{/*			</Select>*/}
					{/*		</FormControl>*/}
					{/*	</Box>*/}
					{/*	<Box>{now().locale('zh-hk').format('YYYY年MMMDo dddd')}</Box>*/}
					{/*</Box>*/}

					<Box mb={3}>
						{teacher_classes.length === 0 && teacher_subjects.length === 0 ? (
							<Box>今年度沒有管理班別</Box>
						) : (
							<Fragment>
								<Grid container spacing={5}>
									{teacher_classes.length > 0 &&
										teacher_classes.map((teacher_class, index) => {
											const filteredHomeworks = filter(class_homeworks, {
												class_id: teacher_class.class_id,
											});
											return (
												<Grid key={index} item xs={12} sm={6} md={4}>
													<Link
														to={'/class-homework/' + teacher_class.class_id}
														className="link"
													>
														<Paper className="card">
															<Box
																p={2}
																display="flex"
																alignItems="center"
																className="card-header green-background"
															>
																<Icon>group</Icon>
																<Box mx={1} />
																<Typography variant="h6">
																	{`${teacher_class.class_name}班`}
																</Typography>
																<Box style={{ flexGrow: 1 }} />
																{filteredHomeworks.length > 0 && (
																	<Chip
																		color="secondary"
																		label={filteredHomeworks.length}
																	/>
																)}
															</Box>
															<Box p={3} className="card-content">
																{map(filteredHomeworks, (homework, index) => {
																	if (index < 3) {
																		return (
																			<Box
																				key={index}
																				mb={1}
																				display="flex"
																				alignItems="center"
																			>
																				<Icon className="margin-right-10 grey-text">
																					description
																				</Icon>
																				<Box display="flex">
																					<Typography variant="body1">
																						<b>{`${homework.subject_name}:`}</b>
																					</Typography>
																					<Box mx={0.5} />
																					<Typography variant="body1" noWrap>
																						{homework.description}
																					</Typography>
																				</Box>
																			</Box>
																		);
																	}
																})}
																{filteredHomeworks.length > 3 && (
																	<Box my={1.5} textAlign="center">
																		<Icon className="grey-text">more_horiz</Icon>
																	</Box>
																)}
																{filteredHomeworks.length === 0 && (
																	<Box textAlign="center">今天沒有需要遞交之功課</Box>
																)}
															</Box>
														</Paper>
													</Link>
												</Grid>
											);
										})}

									{teacher_subjects.length > 0 &&
										teacher_subjects.map((teacher_subject, index) => {
											const filteredHomeworks = filter(subject_homeworks, {
												class_id: teacher_subject.class_id,
												subject_id: teacher_subject.subject_id,
											});
											return (
												<Grid key={index} item xs={12} sm={6} md={4}>
													<Link
														to={
															'/subject-homework/' +
															teacher_subject.class_id +
															'/' +
															teacher_subject.subject_id
														}
														className="link"
													>
														<Paper key={index} className="card">
															<Box
																p={2}
																display="flex"
																alignItems="center"
																className="card-header green-background"
															>
																<Icon>library_books</Icon>
																<Box mx={1}>{teacher_subject.class_name}班</Box>
																<Box>{teacher_subject.subject_name}</Box>
															</Box>
															<Box p={3} className="card-content">
																{map(filteredHomeworks, (homework, index) => {
																	if (index < 3) {
																		return (
																			<Box
																				key={index}
																				mb={1}
																				display="flex"
																				alignItems="center"
																			>
																				<Icon
																					color="primary"
																					className="margin-right-10"
																				>
																					description
																				</Icon>
																				{homework.subject_name}:{' '}
																				{homework.description}
																			</Box>
																		);
																	}
																})}
																{filteredHomeworks.length > 3 && (
																	<Box textAlign="center">
																		<Icon
																			color="primary"
																			className="margin-right-10"
																		>
																			more_horiz
																		</Icon>
																	</Box>
																)}
																{filteredHomeworks.length === 0 && (
																	<Box textAlign="center">今天沒有需要遞交之功課</Box>
																)}
															</Box>
														</Paper>
													</Link>
												</Grid>
											);
										})}
								</Grid>
							</Fragment>
						)}
					</Box>
				</Container>
			</div>
		</Fragment>
	);
};
export default CourseList;
