import React, { Fragment, useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Icon, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { post } from '../helpers/ApiHelper';
import Loader from './Loader';
import Config from '../const/Config';

const student_menu = [
  {
    text: '功課列表',
    link: '/student_dashboard',
    icon: 'menu_book'
  },
  {
    text: '仍欠交功課',
    link: '/outstanding',
    icon: 'cancel_presentation'
  },
];

const teacher_menu = [
  {
    text: '課程列表',
    link: '/course-list',
    icon: 'menu_book'
  },
];

const admin_menu = [
  {
    text: '年度設定',
    link: '/year-setting',
    icon: 'today'
  },
  {
    text: '學生設定',
    link: '/student-setting',
    icon: 'people_alt'
  },
  {
    text: '老師設定',
    link: '/teacher-setting',
    icon: 'person_pin'
  },
  {
    text: '老師科目設定',
    link: '/teacher-subject-setting',
    icon: 'how_to_reg'
  },
  {
    text: '科目設定',
    link: '/subject-setting',
    icon: 'library_books'
  },
  {
    text: '家課設定',
    link: '/homework-type-setting',
    icon: 'list_alt'
  }
];

const Nav = ({ home = false, title }) => {
  const history = useHistory();
  const context = useContext(AuthContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const toggleMenu = () => {
    setOpenMenu(prevState => !prevState);
  }

  const onLogout = async () => {
    setIsLoading(true);
    if (['teacher', 'student'].includes(context.role)) await post('userLogout');
    await context.toggleAuth(Object.create(null));
    history.push('/');
  };

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <AppBar position="fixed">
        <Toolbar>
          {!home &&
            <IconButton edge="start" color='inherit' onClick={() => toggleMenu(true)}>
              <Icon>menu</Icon>
            </IconButton>
          }
          <Typography position="fixed" className='flex-grow-1'>
            <b>{title || `${Config.schoolName}學校家課管理`}</b>
          </Typography>
          <Typography position="fixed">
            <b>{context.chi_name || context.eng_name || ''}</b>
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer classes={{ paper: 'drawer-paper' }} className='drawer' open={openMenu} onClose={() => toggleMenu(false)}>
        <List>
          <ListItem>
            <ListItemIcon>
              <Icon>account_circle</Icon>
            </ListItemIcon>
            <ListItemText primary={context.chi_name || context.eng_name || ''} />
          </ListItem>

          <Divider />

          {context.role === 'student' &&
            student_menu.map((item, index) => (
              <Link to={item.link} key={index} className='link'>
                <ListItem button>
                  <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))
          }

          {context.role === 'teacher' &&
            teacher_menu.map((item, index) => (
              <Link to={item.link} key={index} className='link'>
                <ListItem button>
                  <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))
          }

          <Divider />

          {!!context.isAdmin &&
            admin_menu.map((item, index) => (
              <Link to={item.link} key={index} className='link'>
                <ListItem button>
                  <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))
          }

          <Divider />
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText primary="登出" />
          </ListItem>
        </List>
      </Drawer>
    </Fragment>
  )

}
export default Nav;