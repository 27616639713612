// React
import React, { Component, Fragment } from 'react';

// Material ui
import {
	Container,
	Box,
	Fab,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableCell,
	TableSortLabel,
	TableBody,
	TableRow,
	Icon,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	TextField,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Tabs,
	Tab,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

// Helper
import { get, post } from '../../../helpers/ApiHelper';
import { excelTemplate } from '../../../helpers/ExcelHelper';

// Lodash
import { map, compact, find, orderBy, filter } from 'lodash';

// import XLSX
import XLSX from 'xlsx';

// Component
import Nav from '../../../components/Nav';
import ToolbarBuffer from '../../../components/ToolbarBuffer';
import ErrorBox from '../../../components/ErrorBox';
import TableNoData from '../../../components/TableNoData';
import Loader from '../../../components/Loader';

class HomeworkTypeSetting extends Component {
	state = {
		isLoading: true,

		subjects: [],
		homework_types: [],

		tabIndex: 1,

		open_edit_dialog: false,
		edit_homework_type: {
			id: null,
			subject_id: '',
			name: '',
		},
		edit_homework_type_errors: [],

		table_columns: [
			{ property: 'homework_type_name', name: '家課種類', sort: true },
			{ property: '', name: '行動', sort: false },
		],
		sort_column: null,
		sort_order: 'desc',

		open_delete_dialog: false,
		delete_id: null,

		open_batch_edit_dialog: false,
		homework_type_file: [],
		batch_edit_homework_type_errors: [],

		header_format: ['科目', '家課種類'],
	};

	fetch = (callback) => {
		let getSubject = () =>
			get('getSubject').then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					this.setState({
						subjects: result,
					});
				}
			});

		let getHomeworkType = () =>
			get('getHomeworkType').then(({ status, result }) => {
				if (status !== false && result !== undefined) {
					this.setState({
						homework_types: result,
					});
				}
			});

		Promise.all([getSubject(), getHomeworkType()]).then(() => {
			if (callback) {
				callback();
			}
		});
	};

	sorting = (new_sort_column = 'homework_type_name', new_sort_order = 'asc') => {
		const { homework_types, sort_column, sort_order } = this.state;

		if (new_sort_column === sort_column) {
			if (sort_order === 'asc') {
				new_sort_order = 'desc';
			}
		}

		this.setState({ isLoading: true });
		let new_homework_types = orderBy(homework_types, [new_sort_column], [new_sort_order]);

		this.setState({
			homework_types: new_homework_types,
			sort_column: new_sort_column,
			sort_order: new_sort_order,
			isLoading: false,
		});
	};

	componentDidMount = () => {
		this.fetch(() => {
			const { subjects } = this.state;
			if (subjects) {
				this.setState(
					{
						tabIndex: subjects[0]['id'],
					},
					() => {
						this.sorting();
					}
				);
			}
		});
	};

	newEditHomeworkType = () => {
		return {
			id: null,
			subject_id: '',
			name: '',
		};
	};

	handleTab = (event, tabIndex) => {
		this.setState({ tabIndex });
	};

	handleEditDialog = (status, edit_homework_type = null) => {
		const { newEditHomeworkType } = this;

		if (!edit_homework_type) {
			edit_homework_type = newEditHomeworkType();
		}

		this.setState({
			open_edit_dialog: status,
			edit_homework_type,
			edit_homework_type_errors: [],
		});
	};

	changeEditHomeworkType = (stateName, value) => {
		const { edit_homework_type } = this.state;
		this.setState({ edit_homework_type: { ...edit_homework_type, [stateName]: value } });
	};

	save = () => {
		const { sorting, fetch, newEditHomeworkType } = this;
		const { homework_types, edit_homework_type } = this.state;
		let edit_homework_type_errors = [];

		if (!edit_homework_type.subject_id) {
			edit_homework_type_errors.push('必需輸入科目');
		}

		if (!edit_homework_type.name) {
			edit_homework_type_errors.push('必需輸入家課種類');
		}

		if (
			filter(homework_types, { subject_id: edit_homework_type.subject_id, name: edit_homework_type.name })
				.length > 0
		) {
			edit_homework_type_errors.push('同一科目的家課種類不能重覆');
		}

		if (edit_homework_type_errors.length > 0) {
			this.setState({ edit_homework_type_errors });
		} else {
			this.setState({ isLoading: true });
			post('editHomeworkType', { edit_homework_type }).then(({ status }) => {
				if (status) {
					fetch(() => {
						this.setState(
							{
								isLoading: false,
								open_edit_dialog: false,
								edit_homework_type: newEditHomeworkType(),
								edit_homework_type_errors: [],
								tabIndex: edit_homework_type.subject_id,
							},
							() => {
								sorting();
							}
						);
					});
				}
			});
		}
	};

	handleRemoveDialog = (status, id = null) => {
		this.setState({
			open_delete_dialog: status,
			delete_id: id,
		});
	};

	remove = () => {
		const { fetch } = this;
		const { delete_id } = this.state;

		this.setState({ isLoading: true });

		post('deleteHomeworkType', { homework_type_id: delete_id }).then(({ status }) => {
			if (status) {
				fetch(() => {
					this.setState({
						isLoading: false,
						open_delete_dialog: false,
						delete_id: null,
					});
				});
			}
		});
	};

	handleBatchEditDialog = (status) => {
		this.setState({
			open_batch_edit_dialog: status,
			homework_type_file: [],
			batch_edit_homework_type_errors: [],
		});
	};

	onUploadFile = (homework_type_file) => {
		this.setState({ homework_type_file });
	};

	saveBatch = () => {
		const { fetch } = this;
		const { homework_type_file, header_format, subjects } = this.state;
		let batch_edit_homework_type_errors = [];

		if (homework_type_file.length === 0) {
			batch_edit_homework_type_errors.push('必需以XLSX格式上家課種類');
		}

		if (batch_edit_homework_type_errors.length > 0) {
			this.setState({ batch_edit_homework_type_errors });
		} else {
			const file = homework_type_file[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				this.setState({ isLoading: true });

				const workbook = XLSX.read(e.target.result, { type: 'array' });
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];

				// [subject_name, name]
				const header_data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
				const header = header_data[0];

				if (JSON.stringify(header) !== JSON.stringify(header_format)) {
					batch_edit_homework_type_errors.push('請參照範本格式上載家課種類');
					this.setState({
						batch_edit_homework_type_errors,
						isLoading: false,
					});
				} else {
					const homework_type_data = XLSX.utils.sheet_to_json(worksheet, {
						header: 1,
						range: 1,
						blankrows: false,
					});
					const edit_homework_type = {
						homework_type_data: compact(
							map(homework_type_data, (data) => {
								const subject = find(subjects, { name: data[0] });
								if (subject && subject.id) {
									return {
										subject_id: subject.id,
										name: data[1],
									};
								}
							})
						),
					};

					post('editBatchHomeworkType', { edit_homework_type }).then(({ status }) => {
						if (status) {
							fetch(() => {
								this.setState({
									isLoading: false,
									open_batch_edit_dialog: false,
									homework_type_file: [],
									batch_edit_homework_type_errors: [],
								});
							});
						}
					});
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	download = () => {
		let { header_format } = this.state;
		let title = '家課種類資料';
		let data = [header_format];

		const homework_types = orderBy(this.state.homework_types, ['subject_id'], ['asc']);
		map(homework_types, ({ subject_id, name }) => {
			const subject = find(this.state.subjects, { id: subject_id });
			if (subject) {
				data.push([subject.name, name]);
			}
		});
		excelTemplate(title, data);
	};

	render() {
		const { history } = this.props;
		const {
			isLoading,
			subjects,
			homework_types,
			tabIndex,
			table_columns,
			sort_column,
			sort_order,
			open_edit_dialog,
			edit_homework_type,
			edit_homework_type_errors,
			open_delete_dialog,
			open_batch_edit_dialog,
			batch_edit_homework_type_errors,
		} = this.state;
		const {
			sorting,
			handleTab,
			handleEditDialog,
			changeEditHomeworkType,
			save,
			handleRemoveDialog,
			remove,
			handleBatchEditDialog,
			onUploadFile,
			saveBatch,
			download,
		} = this;

		return (
			<Fragment>
				<Loader isLoading={isLoading} />

				<div className="root">
					<Nav history={history} title="家課設定" />

					<Container className="root-content">
						<ToolbarBuffer />

						<Box mb={3} display="flex" alignItems="center">
							<Box mr={3}>
								<Fab
									variant="extended"
									color="primary"
									size="medium"
									onClick={() => handleEditDialog(true)}
								>
									<Icon>add</Icon>新增家課種類
								</Fab>
							</Box>

							<Box mr={3}>
								<Fab
									variant="extended"
									color="primary"
									size="medium"
									onClick={() => handleBatchEditDialog(true)}
								>
									<Icon className="margin-right-10">cloud_upload</Icon>批量更新家課種類
								</Fab>
							</Box>

							<Box>
								<Fab variant="extended" color="primary" size="medium" onClick={() => download(true)}>
									<Icon className="margin-right-10">cloud_download</Icon>下載家課種類範本
								</Fab>
							</Box>
						</Box>

						<Box mb={3}>
							<Tabs
								value={tabIndex}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								onChange={handleTab}
							>
								{subjects.map((subject, index) => (
									<Tab key={index} value={subject.id} label={subject.name} />
								))}
							</Tabs>
						</Box>

						<Box>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											{table_columns.map((column, index) => (
												<TableCell
													key={index}
													className={column.sort ? 'table-header pointer' : 'table-header'}
													align="center"
													onClick={() => {
														column.sort && sorting(column.property);
													}}
												>
													{column.sort && (
														<TableSortLabel
															className="margin-right-10"
															active={sort_column === column.property}
															direction={sort_order}
															hideSortIcon
														/>
													)}
													{column.name}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{homework_types.filter((homework_type) => homework_type.subject_id === tabIndex)
											.length > 0 ? (
											homework_types
												.filter((homework_type) => homework_type.subject_id === tabIndex)
												.map((homework_type, index) => (
													<TableRow hover key={index}>
														<TableCell component="th" scope="row" align="center">
															{homework_type.name}
														</TableCell>
														<TableCell align="center">
															<Box
																display="flex"
																justifyContent="center"
																alignItems="center"
															>
																<Box mr={2}>
																	<Fab
																		size="small"
																		color="primary"
																		onClick={() =>
																			handleEditDialog(true, homework_type)
																		}
																	>
																		<Icon>edit</Icon>
																	</Fab>
																</Box>
																<Box>
																	<Fab
																		size="small"
																		color="secondary"
																		onClick={() =>
																			handleRemoveDialog(true, homework_type.id)
																		}
																	>
																		<Icon>delete</Icon>
																	</Fab>
																</Box>
															</Box>
														</TableCell>
													</TableRow>
												))
										) : (
											<TableNoData colSpan="2" msg="沒有家課種類" />
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						<Dialog fullScreen open={open_edit_dialog} onClose={() => handleEditDialog(false)}>
							<AppBar className="dialog-app-bar">
								<Toolbar>
									<IconButton color="inherit" onClick={() => handleEditDialog(false)}>
										<Icon>close</Icon>
									</IconButton>
									<Typography variant="h6" className="dialog-title">
										編輯家課種類
									</Typography>
								</Toolbar>
							</AppBar>
							<Box mt={3} mx={3}>
								<Container>
									<Box mb={3}>
										<FormControl variant="outlined" fullWidth>
											<InputLabel id="subject">科目</InputLabel>
											<Select
												labelId="subject"
												value={edit_homework_type.subject_id}
												labelWidth={35}
												onChange={(event) =>
													changeEditHomeworkType('subject_id', event.target.value)
												}
											>
												{subjects.map((subject, index) => (
													<MenuItem key={index} value={subject.id}>
														{subject.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>

									<Box mb={3}>
										<TextField
											label="家課種類"
											variant="outlined"
											fullWidth
											value={edit_homework_type.name}
											onChange={(event) => changeEditHomeworkType('name', event.target.value)}
										/>
									</Box>

									<Box mb={3} display={edit_homework_type_errors.length > 0 ? 'block' : 'none'}>
										<ErrorBox errors={edit_homework_type_errors} />
									</Box>

									<Box mb={3} display="flex" justifyContent="center" alignItems="center">
										<Box mr={3}>
											<Fab
												variant="extended"
												size="medium"
												onClick={() => handleEditDialog(false)}
											>
												<Icon>close</Icon>取消
											</Fab>
										</Box>
										<Box>
											<Fab
												variant="extended"
												color="primary"
												size="medium"
												onClick={() => save()}
											>
												<Icon>save</Icon>儲存
											</Fab>
										</Box>
									</Box>
								</Container>
							</Box>
						</Dialog>

						<Dialog open={open_delete_dialog} onClose={() => handleRemoveDialog(false)}>
							<DialogTitle>確認刪除家課種類？</DialogTitle>
							<DialogContent>
								<DialogContentText>一旦刪除，該種類的相關家課將會一併刪除。</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => handleRemoveDialog(false)}>取消</Button>
								<Button onClick={() => remove()} color="secondary">
									刪除
								</Button>
							</DialogActions>
						</Dialog>

						{/* edit student dialog */}
						<Dialog fullScreen open={open_batch_edit_dialog} onClose={() => handleBatchEditDialog(false)}>
							<AppBar className="dialog-app-bar">
								<Toolbar>
									<IconButton color="inherit" onClick={() => handleBatchEditDialog(false)}>
										<Icon>close</Icon>
									</IconButton>
									<Typography variant="h6" className="dialog-title">
										更新家課種類資料
									</Typography>
								</Toolbar>
							</AppBar>
							<Box mt={3} mx={3}>
								<Container>
									<Box mb={3} textAlign="center">
										<DropzoneArea
											dropzoneClass="dropzone"
											dropzoneText="請以XLSX格式上載家課種類"
											useChipsForPreview
											acceptedFiles={[
												'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
											]}
											filesLimit={1}
											onChange={(student_file) => onUploadFile(student_file)}
										/>
									</Box>

									<Box mb={3} display={batch_edit_homework_type_errors.length > 0 ? 'block' : 'none'}>
										<ErrorBox errors={batch_edit_homework_type_errors} />
									</Box>

									<Box display="flex" justifyContent="center" alignItems="center">
										<Box mr={3}>
											<Fab
												variant="extended"
												size="medium"
												onClick={() => handleBatchEditDialog(false)}
											>
												<Icon>close</Icon>取消
											</Fab>
										</Box>
										<Box>
											<Fab
												variant="extended"
												color="primary"
												size="medium"
												onClick={() => saveBatch()}
											>
												<Icon>save</Icon>儲存
											</Fab>
										</Box>
									</Box>
								</Container>
							</Box>
						</Dialog>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default HomeworkTypeSetting;
