export default {
  // // local
  // host: 'http://localhost:8500',
  // clientId: '317071579010-bi9pm3iht438085305lc3tuk38vt4dsh.apps.googleusercontent.com',
  // schoolName: 'Coding101',
  // superAdmin: 'project.coding101@gmail.com',
  // function: {
  //   teacher: true,
  //   student: true,
  //   classroom: true,
  //   parent: true,
  // },

  // // demo
  // host: 'https://demo.coding101.hk',
  // clientId: '317071579010-bi9pm3iht438085305lc3tuk38vt4dsh.apps.googleusercontent.com',
  // schoolName: 'Coding101',
  // superAdmin: 'project.coding101@gmail.com',
  // function: {
  //     teacher: true,
  //     student: true,
  //     classroom: true,
  //     parent: true,
  // }

  // // inask
  // host: 'https://inask.online',
  // clientId: '317071579010-bi9pm3iht438085305lc3tuk38vt4dsh.apps.googleusercontent.com',
  // schoolName: 'Coding101',
  // superAdmin: 'project.coding101@gmail.com',
  // function: {
  //     teacher: true,
  //     student: true,
  //     classroom: true,
  //     parent: true,
  // }

  // saps
  host: 'https://saps.schadmin.com',
  clientId: '317071579010-bi9pm3iht438085305lc3tuk38vt4dsh.apps.googleusercontent.com',
  schoolName: '聖安當小學',
  superAdmin: 'project.coding101@gmail.com',
  function: {
    teacher: true,
    student: true,
    classroom: true,
    parent: true,
  },
};
